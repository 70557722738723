import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//slices
import { setSubscription } from '../../../../../../../../store/slices/subscriptionsSlice';

const Step4CreateSubscription = forwardRef(({}, ref) => {
    const dispatch = useDispatch();
    const paymentMethodId = sessionStorage.getItem('payment_method_id');
    const { loading } = useSelector(state => state.billing);

    useImperativeHandle(ref, () => ({ handleSubmit, }));

    const handleSubmit = async () => {
        if (!paymentMethodId) {
            throw new Error('Payment method information is missing. Please go back and restart the process.');
        }

        try {
            await dispatch(setSubscription({ paymentMethodId, planType: 'basic' })).unwrap();
        } catch (err) {
            throw new Error('Failed to create subscription.');
        }
    };

    return (
        <div className="p-6 bg-white">
            <h2 className="text-2xl font-bold mb-6 text-gray-800">Step 4: Create Subscription</h2>
            {loading && <div className="text-gray-500">Creating subscription...</div>}
        </div>
    );
});

export default Step4CreateSubscription;
