import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
//assets
import { CreditCardIcon, CubeIcon, FingerPrintIcon, UserCircleIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline';
//components
import { Dialog } from '@headlessui/react';
//utils
import { isHaveAccount } from '../../utils';
import { classNames } from '../../../../../../utils/main_utils';


const subscriptionNavigation = [
    {
        name: 'Create Subscription',
        id: 'createSubscription',
        icon: UserCircleIcon,
        path: '/payments/createSubscription',
    },
    {
        name: 'Show Subscription',
        id: 'showSubscription',
        icon: CreditCardIcon,
        path: '/payments/showSubscription',
    },
];

const customerNavigation = [
    {
        name: 'Billing Details',
        id: 'billingDetails',
        icon: CubeIcon,
        path: '/payments/billingDetails',
    },
    {
        name: 'Add Billing Details',
        id: 'addBillingDetails',
        icon: FingerPrintIcon,
        path: '/payments/addBillingDetails',
    },
    {
        name: 'Customer Update',
        id: 'customerUpdate',
        icon: UsersIcon,
        path: '/payments/customerUpdate',
    },
    {
        name: 'Customer Info',
        id: 'customerInfo',
        icon: UsersIcon,
        path: '/payments/customerInfo',
    },
];


const SubNavigation = () => {
    const { data } = useSelector(state => state.customer);
    const { data: subscriptionsData } = useSelector(state => state.subscription);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const history = useHistory();
    const { url } = useRouteMatch();

    let isHaveCustomer = false;

    if (data) {
        isHaveCustomer = isHaveAccount(data.customer_data);
    }

    let subscription = null;

    if (subscriptionsData) {
        subscription = subscriptionsData.subscription;
    }

    return (
        <>
            <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:flex-none lg:border-0 lg:py-20">
                <nav className="flex-none px-4 sm:px-6 lg:px-0">
                    <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                        {subscriptionNavigation.map(item => (
                            <li key={item.name}>
                                <button
                                    onClick={() => history.push(`${url}${item.path}`)}
                                    className={classNames(
                                        window.location.pathname === `${url}${item.path}` ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                        !subscription && item.id !== 'createSubscription' ? 'cursor-not-allowed opacity-50' : '',
                                        subscription && item.id === 'createSubscription' ? 'cursor-not-allowed opacity-50' : '',
                                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6'
                                    )}
                                    disabled={(!subscription && item.id !== 'createSubscription') || (subscription && item.id === 'createSubscription')}
                                >
                                    <item.icon
                                        aria-hidden="true"
                                        className={classNames(window.location.pathname === `${url}${item.path}` ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0')}
                                    />
                                    {item.name}
                                </button>
                            </li>
                        ))}
                        {customerNavigation.map(item => (
                            <li key={item.name}>
                                <button
                                    onClick={() => history.push(`${url}${item.path}`)}
                                    className={classNames(
                                        window.location.pathname === `${url}${item.path}` ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                        !isHaveCustomer ? 'cursor-not-allowed opacity-50' : '',
                                        'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6'
                                    )}
                                    disabled={!isHaveCustomer}
                                >
                                    <item.icon
                                        aria-hidden="true"
                                        className={
                                            classNames(
                                                window.location.pathname === `${url}${item.path}` ? 
                                                    'text-indigo-600' : 
                                                    'text-gray-400 group-hover:text-indigo-600', 
                                                'h-6 w-6 shrink-0'
                                            )
                                        } />
                                    {item.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </aside>
        </>
    );
};

export default SubNavigation;
