import React, {useEffect, useState, useRef} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//components
import Btn from '../../../../components/buttons/standard/btn';
import Notification from '../../../../components/side_notification/side_notification';
import MasterPopup from '../../../../components/popups/main_popup';
import { SimpleDropdownContainer } from '../../../../components/dropdowns/simple_dropdown/simple_dropdown';
//sections
import RoiCalculator from './components/roiCalculator/roi_calculator';
//utils
import { getClientData, postReqOptBuilder } from '../../../../utils/main_utils';
//styles
import "./home.css";
//assets
import multifamilyImg from "../../../../assets/landing_page/multifamily.png";
import singleFamilyImg from "../../../../assets/landing_page/single-family.png";
import studentHousingImg from "../../../../assets/landing_page/student-housing.png";
import furnishedRentalsImg from "../../../../assets/landing_page/furnished-rental.png";
import logo from "../../../../assets/icons/logo.svg"
import ddArrow from "../../../../assets/icons/dd-arrow.svg";
import gridBG from "../../../../assets/icons/GridBG.svg";
import starIcon from "../../../../assets/icons/rating-star.svg";
import barGraph from "../../../../assets/icons/bar-graph.svg";
import alertIcon from "../../../../assets/icons/alert-icon.svg";
import multifamilyIcon from "../../../../assets/icons/multifamilyIcon.svg";
import singleFamilyIcon from "../../../../assets/icons/singleFamilyIcon.svg";
import studentHousingIcon from "../../../../assets/icons/studentHousingIcon.svg";
import furnishedRentalIcon from "../../../../assets/icons/furnishedRentalIcon.svg";
import scalableIcon from "../../../../assets/icons/settingsIcon.svg";
import safeDepositIcon from "../../../../assets/icons/safeIcon.svg";
import transparencyIcon from "../../../../assets/icons/transparencyIcon.svg";
import portfolioIcon from "../../../../assets/icons/portfolioIcon.svg";
import dashboardImg from "../../../../assets/icons/dashboardImg.png";
import dashboard from "../../../../assets/landing_page/dashboardV2.jpg";
import report from "../../../../assets/landing_page/Report-With-Baseline.jpg";
import facebook from "../../../../assets/landing_page/facebook-2.svg";
import instagram from "../../../../assets/landing_page/instagram-2.svg";
import linkedin from "../../../../assets/landing_page/linkedin-2.svg";
import twitter from "../../../../assets/landing_page/twitter-2.svg";
import bannerVideo from "../../../../assets/landing_page/banner_video2.mp4";
import easyWebApp from "../../../../assets/landing_page/easy-web-app.mp4";
import ContactForm from './components/contact/contactUs';

export default function HomePage(props) {
    const history = useHistory();

    const [clientData, setClientData] = useState(null);
    const [notifState, setNotifState] = useState(false);
    const [popupState, setPopupState] = useState(null);
    const [showSolution, setShowSolution] = useState(0);
    const [showFaq, setShowFaq] = useState(null);
    const scrollToForm = useRef(null);
    const solutionsContainer = useRef(null);
    const solutionsContainer1 = useRef(null);
    const solutionsContainer2 = useRef(null);
    const solutionsContainer3 = useRef(null);
    
    useEffect(()=> {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        getClientData().then(data => {
            setClientData(data);
            fetch(props.para_be + '/log_entry', postReqOptBuilder({ip: data ? data[0] : null}, false))
            .then(response => {
                console.log("Entry log status:", response.status);
                console.log("Entry log Response:", response.text());
            });
        });
        if (window.location.hash === "#request_demo") {
            executeScroll();
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        solutionsContainer.current.scrollIntoView();
                    } else {
                    }
                });
            },
            { threshold: 0.7 }
        );
        const observer2 = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (entry.target.id === "solution-item-1") {
                            setShowSolution(0);
                        } else if (entry.target.id === "solution-item-2") {
                            setShowSolution(1);
                        } else if (entry.target.id === "solution-item-3") {
                            setShowSolution(2);
                        }
                    }
                });
            },
            { threshold: 0.7 }
        );

        const container = solutionsContainer.current;
        // Observe the container to detect when it comes into view
        if (container) {
            observer.observe(container);
            observer2.observe(solutionsContainer1.current);
            observer2.observe(solutionsContainer2.current);
            observer2.observe(solutionsContainer3.current);
        }
        
        // Cleanup observer when component unmounts
        return () => {
            observer.disconnect();
            observer2.disconnect();
        }
    // eslint-disable-next-line no-sequences
    }, []);

    const executeScroll = (e) => scrollToForm.current.scrollIntoView();


    return (
        <div className="in-homepage-container">
            <Helmet>
                <title>Paraspot AI | Remote and Automatic Property Inspections</title>
                <meta name="description" content="Paraspot AI revolutionizes property inspections with AI-powered remote scans, reducing costs, boosting tenant satisfaction, and eliminating security deposit disputes for property managers in multifamily, single-family, and rental markets. Put your operations on autopilot!"/>
                <meta property="og:title" content="Paraspot AI | Remote and Automatic Property Inspections"/>
                <meta property="og:description" content="Paraspot AI revolutionizes property inspections with AI-powered remote scans, reducing costs, boosting tenant satisfaction, and eliminating security deposit disputes for property managers in multifamily, single-family, and rental markets. Put your operations on autopilot!"/>
                <meta name="robots" content="index, follow"/>
            </Helmet>

            <div className='in-homepage-nav-wrapper-parent'>
                <div className='in-homepage-nav-wrapper'>
                    <div className='in-homepage-nav flexRow'>
                        <div className='in-homepage-nav-logo flexRow'>
                            <img src={logo} alt="Paraspot AI Logo"/>
                        </div>
                        <div className='in-homepage-nav-links flexRow'>
                            <SimpleDropdownContainer 
                                extraClasses="in-nav-link flexRow show-on-hover d-none"
                                showDropdown={false}
                                borderedItems={false}
                                items={{
                                    'multifamily': {'present': 'Multifamily', 'onclick': (k, e) => history.push('/solutions/multifamily')},
                                    'single_family': {'present': 'Single Family', 'onclick': (k, e) => history.push('/solutions/single-family')},
                                    'student_housing': {'present': 'Student Housing', 'onclick': (k, e) => history.push('/solutions/student-housing')},
                                    'serviced_apartments': {'present': 'Furnished Rentals & Co-Living', 'onclick': (k, e) => history.push('/solutions/furnished-rentals-co-living')},
                                    'str': {'present': 'Short-Term Rentals', 'onclick': (k, e) => history.push('/solutions/str')},
                                }}>
                                <div className='in-homepage-nav-link text-0'>Solutions</div>
                                <img src={ddArrow} alt="arrow-down"/>
                            </SimpleDropdownContainer>
                            <SimpleDropdownContainer 
                                extraClasses="in-nav-link flexRow show-on-hover d-none"
                                showDropdown={false}
                                borderedItems={false}
                                items={{
                                    'blog': {'present': 'Blog', 'onclick': (k, e) => history.push('/resources/blog')},
                                    'faqs': {'present': 'FAQs', 'onclick': (k, e) => history.push('/resources/faqs')},
                                    'integrations': {'present': 'Integrations', 'onclick': (k, e) => history.push('/resources/integrations')},
                                    'api_documentation': {'present': 'API Documentation', 'onclick': (k, e) => history.push('/resources/documentation')},
                                }}>
                                <div className='in-homepage-nav-link text-0'>Resources</div>
                                <img src={ddArrow} alt="arrow-down"/>
                            </SimpleDropdownContainer>
                            <SimpleDropdownContainer 
                                extraClasses="in-nav-link flexRow show-on-hover d-none"
                                showDropdown={false}
                                borderedItems={false}
                                items={{
                                    'mission': {'present': 'Our Mission', 'onclick': (k, e) => history.push('/about-us/our-mission')},
                                    'team': {'present': 'Team', 'onclick': (k, e) => history.push('/about-us/team')},
                                    'contact_us': {'present': 'Contact Us', 'onclick': (k, e) => history.push('/about-us/contact-us')},
                                }}>
                                <div className='in-homepage-nav-link text-0'>About Us</div>
                                <img src={ddArrow} alt="arrow-down"/>
                            </SimpleDropdownContainer>
                        </div>
                        <div className='in-hopepage-nav-cta flexRow'>
                            <Btn text="Log In" type="secondary" extraClasses="btn-no-border btn-no-bg text-3-2 btn-radius6" onclick={() => { history.push("/login"); }}/>
                            <Btn text="Request Demo" type="primary-black" extraClasses="text-3-2 btn-radius6" onclick={executeScroll}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='in-homepage-viewport in-homepage-section-simple'>
                <img className='in-homepage-viewport-bg' src={gridBG} alt="bg"/>
                <div className='in-homepage-viewport-bg-radial'/>

                <div className='in-homepage-viewport-content'>
                    <h1 className='h1'>
                        The AI Solution to Fully Automate Your <br/>
                        Property Inspections
                    </h1>
                    <h2 className='text-0'>
                        Automate inspections, eliminate disputes, and scale effortlessly.<br/>
                        Discover the power of AI-driven property management today.
                    </h2>
                    <Btn 
                        text={
                            <>
                                Get Your Free Trial
                                <img src={ddArrow} alt="arrow-right"/>
                            </>
                        } 
                        onclick={executeScroll}
                        type="primary" 
                        extraClasses="text-3-2 cta-arrow-btn btn-radius6" />

                    <div className='in-homepage-viewport-content-video'>
                        <video src={bannerVideo} type="video/mp4" autoplay="" loop muted={true}/>
                    </div>
                </div>
            </div>

            <div ref={solutionsContainer} className='in-homepage-solution'>
                <div className='in-homepage-solution-content flexRow'>
                    <div className='in-homepage-solution-text-abs-wrapper'>
                        <div className='in-homepage-solution-text flexColumn'>
                            <div className='in-home-page-solution-title'>
                                <div className='h2'>
                                    Our Solutions
                                </div>
                            </div>
                            <div 
                                className={`in-homepage-solution-about${showSolution === 0 ? " active-solution-item" : ""} flexRow`} 
                                onClick={() => {
                                    setShowSolution(0);
                                    solutionsContainer1.current.scrollIntoView();
                                }}
                            >
                                <div className='vertical-line'/>
                                <div className='in-homepage-solution-about-content'>
                                    <div className='in-homepage-solution-gradient1 mobile-only'/>
                                    <div className='in-homepage-solution-gradient2 mobile-only'/>
                                    <div className='in-homepage-solution-about-item-title para-label-v2-gradient1'>
                                        <h1 className='text-3-2'>
                                            AI-Powered Property Inspections
                                        </h1>
                                    </div>
                                    <h2 className='h3'>
                                        Fully automated and remote.
                                    </h2>
                                    <p className='text-1'>
                                        Empower your tenants to perform AI-guided & verified
                                        property inspections, effortlessly conducted from any mobile device.
                                    </p>
                                    <div ref={solutionsContainer1} className='in-homepage-solution-media-item mobile-only'>
                                        <div className='solution-media-container flexRow'>
                                            <img className='solution-media-container-bg' src={gridBG} alt="bg"/>
                                            <video src={easyWebApp} type="video/mp4" autoplay="" loop muted={true}/>
                                            <div className='solution-media-container-bottom-blur'/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div 
                                className={`in-homepage-solution-about${showSolution === 1 ? " active-solution-item" : ""} flexRow`} 
                                onClick={() => {
                                    setShowSolution(1);
                                    solutionsContainer2.current.scrollIntoView();
                                }}
                            >
                                <div className='vertical-line'/>
                                <div className='in-homepage-solution-about-content'>
                                    <div className='in-homepage-solution-gradient1 mobile-only'/>
                                    <div className='in-homepage-solution-gradient2 mobile-only'/>
                                    <div className='in-homepage-solution-about-item-title para-label-v2-gradient2'>
                                        <h1 className='text-3-2'>
                                            Automated Inspection Reports
                                        </h1>
                                    </div>
                                    <h2 className='h3'>
                                        Get details, AI-generated<br/>
                                        property reports instantly.
                                    </h2>
                                    <p className='text-1'>
                                        Our reports highlight damages like 
                                        cracks, stains, missing items, and required maintenance, 
                                        ensuring faster turnovers and fewer disputes.
                                    </p>
                                    <div ref={solutionsContainer2} className='in-homepage-solution-media-item mobile-only'>
                                        <div className='solution-media-container flexRow'>
                                            <img className='solution-media-container-bg' src={gridBG} alt="bg"/>
                                            <img src={report} alt="AI-generated report - Automatically on Paraspot AI platform"/>
                                            <div className='solution-media-container-bottom-blur'/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div 
                                className={`in-homepage-solution-about${showSolution === 2 ? " active-solution-item" : ""} flexRow`} 
                                onClick={() => {
                                    setShowSolution(2);
                                    solutionsContainer3.current.scrollIntoView();
                                }}
                            >
                                <div className='vertical-line'/>
                                <div className='in-homepage-solution-about-content'>
                                    <div className='in-homepage-solution-gradient1 mobile-only'/>
                                    <div className='in-homepage-solution-gradient2 mobile-only'/>
                                    <div className='in-homepage-solution-about-item-title para-label-v2-gradient3'>
                                        <h1 className='text-3-2'>
                                            Portfolio Management Dashboard
                                        </h1>
                                    </div>
                                    <h2 className='h3'>
                                        Get a clear, real-time view of your operations.
                                    </h2>
                                    <p className='text-1'>
                                        Use our Dashboard to track move-ins, move-outs, AI-inspections, and
                                        automated reports in one place. Streamline & centralize
                                        your portfolio management for maximum efficiency.
                                    </p>
                                    <div ref={solutionsContainer3} className='in-homepage-solution-media-item mobile-only'>
                                        <div className='solution-media-container flexRow'>
                                            <img className='solution-media-container-bg' src={gridBG} alt="bg"/>
                                            <img src={dashboard} alt="Dashboard for portfolio management from a single place on Paraspot AI platform"/>
                                            <div className='solution-media-container-bottom-blur'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='in-homepage-solution-media'>
                        {/* <div className='in-homepage-solution-gradient1 hide-mobile'/>
                        <div className='in-homepage-solution-gradient2 hide-mobile'/> */}

                        <div className='in-homepage-solution-media-items hide-mobile'>
                            <div id="solution-item-1" ref={solutionsContainer1} className='in-homepage-solution-media-item'>
                                <div className='in-homepage-solution-gradient1 hide-mobile'/>
                                <div className='in-homepage-solution-gradient2 hide-mobile'/>
                                <div className='solution-media-container flexRow'>
                                    <img className='solution-media-container-bg' src={gridBG} alt="bg"/>
                                    <video src={easyWebApp} type="video/mp4" autoplay="" loop muted={true}/>
                                    <div className='solution-media-container-bottom-blur'/>
                                </div>
                            </div>
                            <div id="solution-item-2" ref={solutionsContainer2} className='in-homepage-solution-media-item'>
                                <div className='in-homepage-solution-gradient1 hide-mobile'/>
                                <div className='in-homepage-solution-gradient2 hide-mobile'/>
                                <div className='solution-media-container flexRow'>
                                    <img className='solution-media-container-bg' src={gridBG} alt="bg"/>
                                    <img src={report} alt="AI-generated report - Automatically on Paraspot AI platform"/>
                                    <div className='solution-media-container-bottom-blur'/>
                                </div>
                            </div>
                            <div id="solution-item-3" ref={solutionsContainer3} className='in-homepage-solution-media-item'>
                                <div className='in-homepage-solution-gradient1 hide-mobile'/>
                                <div className='in-homepage-solution-gradient2 hide-mobile'/>
                                <div className='solution-media-container flexRow'>
                                    <img className='solution-media-container-bg' src={gridBG} alt="bg"/>
                                    <img src={dashboard} alt="Dashboard for portfolio management from a single place on Paraspot AI platform"/>
                                    <div className='solution-media-container-bottom-blur'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='in-homepage-who-we-are'>
                <div className='in-homepage-who-we-are-content'>
                    <div className='in-homepage-who-we-are-content-text flexRow'>
                        <div className='h2'>
                            Tailored Solutions for<br/>
                            Every Need
                        </div>
                        <div className='text-1'>
                            At Paraspot, we provide tailored AI-driven solutions to meet the unique needs of property managers 
                            across various sectors. Our platform ensures seamless property inspections, improving efficiency and accuracy. 
                            With high completion rates and detailed usage insights, we help you streamline operations and maximize results.
                        </div>
                    </div>
                    <div className='in-homepage-who-we-are-content-media flexRow'>
                        <div className='in-homepage-who-we-are-content-media-item flexColumn'>
                            <div>
                                <div className='h2'>7K+</div>
                                <div className='text-1 in-text-color-2'>Total number of inspections</div>
                            </div>

                            <div className='flexColumn'>
                                <div className='flexRow'>
                                    <img className='in-rating-icon' src={starIcon} alt="rating"/>
                                    <div className='h2'>4.6</div>
                                </div>
                                <div className='text-1 in-text-color-2'>Rating by tenants</div>
                            </div>
                        </div>
                        <div className='in-homepage-who-we-are-content-media-item'>
                            <div className='h3'>Completion rate by tenants</div>
                            <div className='text-1 in-text-color-2'>
                                Tenants love Paraspot for easy, transparent inspections and faster security deposit returns.
                            </div>
                            <div className='h00 in-completion-rate-value'>99%</div>
                        </div>
                        <div className='in-homepage-who-we-are-content-media-item'>
                            <div className='h3'>Minutes saved per inspection</div>
                            <div className='h1'>90</div>
                            <img className='in-bar-graph-icon' src={barGraph} alt="bar-chart"/>
                        </div>
                        <div className='in-homepage-who-we-are-content-media-item'>
                            <div className='wwa-media-item-icon flexRow'>
                                <img src={alertIcon} alt='icon'/>
                            </div>
                            <div className='h00'>1K+</div>
                            <div className='h3'>Issues Detected</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='in-homepage-who-we-serve in-homepage-section-simple'>
                <img className='in-homepage-wws-bg' src={gridBG} alt="bg"/>
                <div className='in-homepage-wws-radial'/>

                <div className='in-homepage-who-we-serve-content flexColumn'>
                    <div className='h2'>
                        Who We Serve
                    </div>
                    <div className='text-1 in-wws-description'>
                        At Paraspot, we partner with visionary property managers, landlords, and operators.<br/>
                        You're a champion of change, and together, we'll elevate your operations and 
                        transform tenant experiences for a better future.
                    </div>
                    <div className='in-homepage-who-we-serve-media flexRow'>
                        <div className='in-homepage-who-we-serve-media-item flexRow'>
                            <img src={multifamilyImg} alt="Multifamily Clients"/>
                            <div className='wws-media-item-icon flexRow'>
                                <img src={multifamilyIcon} alt='icon'/>
                            </div>
                            <div className='wws-media-item-text-box'>
                                <h1 className='h3'>Multifamily</h1>
                                <p className='text-3'>
                                    Turn inspection challenges into an advantage with automated processes, 
                                    consistent reporting, and faster repairs for a better tenant experience.
                                </p>
                            </div>
                        </div>
                        <div className='in-homepage-who-we-serve-media-item flexRow'>
                            <img src={singleFamilyImg} alt="Single-Family Clients"/>
                            <div className='wws-media-item-icon flexRow'>
                                <img src={singleFamilyIcon} alt='icon'/>
                            </div>
                            <div className='wws-media-item-text-box'>
                                <h1 className='h3'>Single Family</h1>
                                <p className='text-3'>
                                    Manage your properties remotely with AI inspections - 
                                    protect your assets while reducing overhead across decentralized units.
                                </p>
                            </div>
                        </div>
                        <div className='in-homepage-who-we-serve-media-item flexRow'>
                            <img src={studentHousingImg} alt="Student Housing Clients"/>
                            <div className='wws-media-item-icon flexRow'>
                                <img src={studentHousingIcon} alt='icon'/>
                            </div>
                            <div className='wws-media-item-text-box'>
                                <h1 className='h3'>Student Housing</h1>
                                <p className='text-3'>
                                    Reduce disputes and enhance efficiency with automated damage reports.
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                        </div>
                        <div className='in-homepage-who-we-serve-media-item flexRow'>
                            <img src={furnishedRentalsImg} alt="Furnished Rentals and Co-Living Clients"/>
                            <div className='wws-media-item-icon flexRow'>
                                <img src={furnishedRentalIcon} alt='icon'/>
                            </div>
                            <div className='wws-media-item-text-box'>
                                <h1 className='h3'>Furnished Rentals & Co-Living</h1>
                                <p className='text-3'>
                                    Ensure faster turnovers and protect assets with AI-guided inspections for accurate condition tracking
                                </p>
                            </div>
                        </div>
                    </div>
                    <Btn 
                        text={
                            <>
                                Get Your Free Trial
                                <img src={ddArrow} alt="arrow-right"/>
                            </>
                        } 
                        onclick={executeScroll}
                        type="primary" 
                        extraClasses="text-3-2 cta-arrow-btn btn-radius6" />
                </div>
            </div>

            <div className='in-homepage-benefits in-homepage-section-simple'>
                <div className='in-homepage-benefits-content flexColumn'>
                    <div className='h2'>Benefits of Using Our Services</div>
                    <div className='text-1'>Join a better way of inspecting your properties</div>
                    <Btn 
                        text={
                            <>
                                Get Your Free Trial
                                <img src={ddArrow} alt="arrow-right"/>
                            </>
                        } 
                        onclick={executeScroll}
                        type="primary" 
                        extraClasses="text-3-2 cta-arrow-btn btn-radius6" />
                    <div className='in-homepage-benefits-media flexRow'>
                        <div className='in-homepage-benefits-media-item flexColumn'>
                            <img className='in-homepage-benefits-bg' src={gridBG} alt="bg"/>
                            <div className='benefits-media-item-icon flexRow'>
                                <img src={scalableIcon} alt='Scalable Operations'/>
                            </div>
                            <h1 className='h2-2'>Scalable Operations</h1>
                            <h2 className='text-3'>
                                Effortlessly scale your business processes without adding overhead.
                            </h2>
                        </div>
                        <div className='in-homepage-benefits-media-item flexColumn'>
                            <img className='in-homepage-benefits-bg' src={gridBG} alt="bg"/>
                            <div className='benefits-media-item-icon flexRow'>
                                <img src={safeDepositIcon} alt='Eliminate disputes and friction upon charges'/>
                            </div>
                            <h1 className='h2-2'>
                                Reduced Disputes<br/>
                                on Security<br/>
                                Deposits
                            </h1>
                            <h2 className='text-3'>
                                Leverage detailed documentation and tenant-led AI inspections to provide clear, 
                                indisputable proof of damages, significantly reducing conflicts over charges.
                            </h2>
                        </div>
                        <div className='in-homepage-benefits-media-item flexColumn'>
                            <img className='in-homepage-benefits-bg' src={gridBG} alt="bg"/>
                            <div className='benefits-media-item-icon flexRow'>
                                <img src={transparencyIcon} alt='Improved Brand'/>
                            </div>
                            <h1 className='h2-2'>
                                Improved<br/>
                                Transparency
                            </h1>
                            <h2 className='text-3'>
                                Foster a better tenant-landlord relationship through open, clear communication 
                                supported by thorough inspection records
                            </h2>
                        </div>
                        <div className='in-homepage-benefits-media-item flexColumn'>
                            <img className='in-homepage-benefits-bg' src={gridBG} alt="bg"/>
                            <div className='benefits-media-item-icon flexRow'>
                                <img src={portfolioIcon} alt='Portfolio Management'/>
                            </div>
                            <h1 className='h2-2'>
                                Comprehensive<br/>
                                Portfolio<br/>
                                Management
                            </h1>
                            <h2 className='text-3'>
                                Maintain a bird's-eye view of your entire property portfolio with 
                                real-time insights, keeping you always informed
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <RoiCalculator executeScroll={executeScroll} />

            <div className='in-homepage-faqs in-homepage-section-simple'>
                <div className='in-homepage-faqs-content flexRow'>
                    <div className='in-homepage-faqs-text flexColumn'>
                        <div className='h2'>
                            Frequently<br/>
                            Asked Questions
                        </div>
                        <div className='text-1'>
                            Do you have any more questions?
                        </div>
                        <div className='flexRow'>
                            {/* <Btn 
                                text="View all FAQs"
                                type="primary" 
                                extraClasses="text-1-3 btn-radius6 btn-no-break" /> */}
                            <Btn 
                                text="Speak to one of our experts"
                                onclick={executeScroll}
                                type="secondary" 
                                extraClasses="text-1-3 btn-secondary-selected btn-radius6 btn-no-bg btn-no-break" />
                        </div>
                    </div>
                    <div className='in-homepage-faqs-media'>
                        {
                            [
                                {
                                    title: "How does Paraspot AI benefit property managers and operators?",
                                    content: (
                                        "Paraspot AI revolutionizes property management by streamlining operations and improving efficiency. " +
                                        "With AI-guided inspections, property managers can eliminate costly, time-consuming site visits and " +
                                        "instead rely on accurate, remote inspections. The platform automates property condition reporting, " +
                                        "delivering real-time, detailed assessments that reduce human error and provide undeniable proof of " +
                                        "damages or wear. By empowering tenants to conduct inspections themselves, Paraspot minimizes " +
                                        "disputes, accelerates turnover times, and enhances tenant satisfaction. It's a cost-effective, " +
                                        "scalable solution that allows you to focus on growing your portfolio while automating day-to-day tasks."
                                    )
                                },
                                {
                                    title: "Why switch from manual inspections to AI-powered inspections?",
                                    content: (
                                        "Switching to AI-guided inspections transforms your property management process. With manual inspections, " +
                                        "you face time-consuming site visits, human error, and inconsistent reporting. AI-driven inspections, on " +
                                        "the other hand, provide precise, automated documentation without ever stepping foot on the property. You " +
                                        "can reduce costs by up to 90%, eliminate repetitive tasks, and get real-time, unbiased reports with " +
                                        "undeniable proof of property conditions. Tenants actively participate in the process, resulting in fewer " +
                                        "disputes and faster resolutions. It's a smarter, faster, and more accurate way to manage your properties. "
                                    )
                                },
                                {
                                    title: "How can I be sure tenants are accurately capturing all details and not missing or hiding anything?",
                                    content: (
                                        "Paraspot AI ensures that every inspection is thorough and accurate by guiding tenants step-by-step through " +
                                        "the process. Our AI-driven system validates the quality of the scan in real-time, ensuring that all areas " +
                                        "of the property are covered. If any part of the property is skipped or improperly scanned, the system flags " +
                                        "it for review, preventing missed details. Additionally, the technology detects inconsistencies or attempts " +
                                        "to hide damage, providing you with full transparency and confidence in the results. With time-stamped and " +
                                        "geo-tagged reports, you get an irrefutable record of the inspection, ensuring tenants can't omit critical information. "
                                    )
                                },
                                {
                                    title: "Can Paraspot help reduce operational costs?",
                                    content: (
                                        "Absolutely. Paraspot significantly reduces operational costs by automating property inspections and eliminating " +
                                        "the need for on-site visits. With our AI-guided inspections, property managers no longer have to spend time and " +
                                        "resources on manual checks. The platform generates detailed, accurate reports remotely, cutting inspection costs " +
                                        "by up to 90%. Additionally, it speeds up turnover times, minimizes tenant disputes, and reduces expenses related " +
                                        "to dispute resolution. By streamlining these processes, Paraspot allows you to manage more properties with fewer " +
                                        "resources, leading to substantial savings across your operations. "
                                    )
                                },
                                {
                                    title: "What is included in Paraspot's property condition assessments?",
                                    content: (
                                        "Paraspot's property condition assessments are comprehensive and highly detailed. Our AI-driven system captures " +
                                        "high-quality scans of the entire property, including its interior, furnishings, and appliances. It automatically " +
                                        "detects damages such as cracks, stains, and wear and tear, while also documenting the condition of key elements " +
                                        "like flooring, walls, and fixtures. The assessment includes time-stamped, geo-tagged data for accuracy, and generates " +
                                        "an easy-to-read report with visual evidence. This ensures that every detail is accounted for, providing clear proof of " +
                                        "the property's condition at both move-in and move-out. "
                                    )
                                },
                                {
                                    title: "How does Paraspot ensure the accuracy of its inspections?",
                                    content: (
                                        "Paraspot guarantees inspection accuracy with its AI, which is trained on over 7 million data points and continuously " +
                                        "learns from thousands of ongoing inspections. This ensures ever-improving precision and consistency. Our system " +
                                        "automatically detects damages—such as cracks, stains, or wear—and captures high-quality scans, reducing human error. " +
                                        "Each inspection is backed by time-stamped, geo-tagged reports, providing verifiable proof of the property's condition. " +
                                        "Additionally, Paraspot's AI compares before-and-after scans to identify discrepancies, ensuring a reliable and  " +
                                        "transparent assessment every time. "
                                    )
                                },
                                {
                                    title: "What kind of support does Paraspot offer to its users?",
                                    content: (
                                        "Paraspot offers comprehensive support to ensure a seamless experience for all users. We provide onboarding assistance, " +
                                        "personalized training sessions, and ongoing technical support to help you get the most out of our platform. Users also " +
                                        "have access to detailed resources such as the onboarding process guide, FAQs, and dedicated customer support for any " +
                                        "issues or questions. Our team is committed to helping you streamline property management and maximize the benefits of Paraspot AI. "
                                    )
                                },
                                {
                                    title: "How does Paraspot support multifamily centralization?",
                                    content: (
                                        "Paraspot streamlines multifamily centralization by allowing property managers to oversee all units from a single, unified " +
                                        "platform. Our AI-powered system enables remote inspections across multiple properties, eliminating the need for on-site " +
                                        "visits and reducing operational complexity. Property managers can centralize their workflows, monitor the condition of all " +
                                        "units in real-time, and generate automated reports for each property. This not only simplifies management across large " +
                                        "portfolios but also ensures consistency, accuracy, and transparency, saving time and resources while maintaining full " +
                                        "control over multifamily operations. "
                                    )
                                },
                                {
                                    title: "How does Paraspot deliver the best automated property inspections?",
                                    content: (
                                        "Paraspot excels at automated property inspections by leveraging advanced AI, developed by a team with deep expertise in both " +
                                        "AI and real estate. Our technology accurately detects damages, guides tenants through a seamless process, and produces high-quality,  " +
                                        "time-stamped reports. Continuous learning from thousands of inspections ensures precision and efficiency, reducing human error " +
                                        "and improving over time. "
                                    )
                                },
                                {
                                    title: "How can Paraspot help reduce operational costs?",
                                    content: (
                                        "Paraspot significantly lowers operational costs by automating the entire inspection process. Our AI-driven platform eliminates " +
                                        "the need for manual site visits, reducing labor and travel expenses. With faster inspections, fewer tenant disputes, and " +
                                        "quicker property turnovers, you save time and resources. By streamlining operations and providing real-time, accurate reports, " +
                                        "Paraspot enables property managers to handle more units efficiently, ultimately maximizing cost savings across your portfolio. "
                                    )
                                },
                            ].map((item, index) => {
                                return (
                                    <div key={index} className='in-homepage-faqs-media-item flexColumn' onClick={() => { setShowFaq(showFaq === index ? null : index) }}>
                                        <div className='in-homepage-faqs-media-item-main flexRow'>
                                            <div className='text-1-3 in-faqs-number'>{index < 9 ? `0${index+1}` : index+1}</div>
                                            <h3 className='text-1-3 in-faqs-text'>
                                                {item.title}
                                            </h3>
                                            <div className='h2-2'>{showFaq === index ? "-" : "+"}</div>
                                        </div>
                                        <div className={`in-faqs-expanded${showFaq === index ? " in-faqs-expanded-show-a" : ""}`}>
                                            <p className='text-1'>
                                                {item.content}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

            <ContactForm
                scrollToForm={scrollToForm}
                clientData={clientData}
                para_be={props.para_be}
                setNotifState={setNotifState} />

            <div className='in-homepage-pre-footer in-homepage-section-simple'>
                <img className='in-homepage-pre-footer-bg' src={gridBG} alt="bg"/>
                <div className='in-homepage-pre-footer-radial1'/>
                <div className='in-homepage-pre-footer-radial2'/>

                <div className='in-homepage-pre-footer-content flexRow'>
                    <div className='in-homepage-pre-footer-text flexColumn'>
                        <h1 className='h1'>
                            Ready to put your<br/>
                            property operations<br/>
                            on autopilot?
                        </h1>
                        <h2 className='text-0'>
                            Experinece effortless management with AI-powered<br/>
                            inspections, and focus on what truly matters.
                        </h2>
                        <Btn 
                            text={
                                <>
                                    Join Today
                                    <img src={ddArrow} alt="arrow-right"/>
                                </>
                            } 
                            onclick={executeScroll}
                            type="primary-black" 
                            extraClasses="text-3-2 cta-arrow-btn btn-radius6" />
                    </div>
                    <div className='in-homepage-pre-footer-media'>
                        <img src={dashboardImg} alt="Paraspot AI operations management dashboard"/>
                    </div>
                </div>
            </div>

            <div className="in-homepage-footer">
                <div className='in-homepage-footer-content'>
                    <div className='in-homepage-footer-content-top flexRow'>
                        <div className='flexColumn'>
                            <h1 className='h2'>
                                Transform Inspections,<br/>
                                Elevate Efficiency - <br/>
                                Powered by AI.
                            </h1>
                            <div className="flexRow">
                                <a className='footer-social-icon' href="https://www.linkedin.com/company/paraspot-gmbh" target="_blank" rel="noreferrer">
                                    <img src={linkedin} alt="linkedIn"/>
                                </a>
                                <a className='footer-social-icon' href="https://www.facebook.com/paraspotAI" target="_blank" rel="noreferrer">
                                    <img src={facebook} alt="facebook"/>
                                </a>
                                <a className='footer-social-icon' href="https://www.instagram.com/paraspot_ai/" target="_blank" rel="noreferrer">
                                    <img src={instagram} alt="instagram"/>
                                </a>
                                <a className='footer-social-icon' href="https://twitter.com/paraspot" target="_blank" rel="noreferrer">
                                    <img src={twitter} alt="twitter"/>
                                </a>
                            </div>
                        </div>
                        <div className='footer-sitemap flexRow d-none'>
                            <div className='footer-sitemap-column'>
                                <div className='text-1-3'>Solutions</div>
                                <Link to="/solutions/multifamily" className='text-1'>Multifamily</Link>
                                <Link to="/solutions/single-family" className='text-1'>Single Family</Link>
                                <Link to="/solutions/student-housing" className='text-1'>Student Housing</Link>
                                <Link to="/solutions/furnished-rental-co-living" className='text-1'>Furnished Rental &<br/>Co-Living</Link>
                                <Link to="/solutions/str" className='text-1'>Short-Term Rentals</Link>
                            </div>
                            <div className='footer-sitemap-column'>
                                <div className='text-1-3'>Resources</div>
                                <Link to="/resources/blog" className='text-1'>Blog</Link>
                                <Link to="/resources/faqs" className='text-1'>FAQs</Link>
                                <Link to="/resources/integrations" className='text-1'>Integrations</Link>
                                <Link to="/resources/documentation" className='text-1'>API Documentation</Link>
                            </div>
                            <div className='footer-sitemap-column'>
                                <div className='text-1-3'>About Us</div>
                                <Link to="/about-us/out-mission" className='text-1'>Our Mission</Link>
                                <Link to="/about-us/team" className='text-1'>Team</Link>
                                <Link to="/about-us/contact-us" className='text-1'>Contact Us</Link>
                            </div>
                        </div>
                    </div>
                    <div className='in-homepage-footer-content-bottom flexRow'>
                        <div className='text-1'>© Paraspot 2024</div>
                        <div className='flexRow'>
                            <div className='text-1'>Terms & Conditions</div>
                            <div className='text-1'>Privacy Policy</div>
                        </div>
                    </div>
                </div>
            </div>

            {notifState ?
                <Notification
                    closeFunc={() => setNotifState(null)}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
            {popupState ? <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup> : ""}
        </div>
    )
}