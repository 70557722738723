import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useOpenController } from '../../../../hooks/useOpenController';
import SnackBar from '../../../../../../../../components/SnackBar';
import ExpandedDetails from '../ExpandedDetails';
import ActionsPanel from './components/ActionsPanel';
import { dateTimeFormater } from '../../../../../../../../utils/date_utils';
import { SeverityLevels } from '../../../../../../../../constants';
import { reportsHeaderConfig } from '../../../../config/table.config';






// Sub-component for the mobile view
const MobileView = ({ ext_ref, subject, tenant_name, inspection_type, report_date , report_id, pid, toggle, isOpen, ts }) => {
  
  
  const headerDataMapping = {
    '1': ext_ref,
    '2': subject,
    '3': tenant_name,
    '4': inspection_type,
    '5': report_date ? dateTimeFormater(report_date, 'yyyy/mm/dd') : '',
  };

  return (
    <div className="border border-gray-200 rounded-lg p-4 mb-4 bg-white shadow-sm" onClick={toggle} key={pid}>
      <div className="flex flex-col mb-3">
         <div className="flex-1">
              {reportsHeaderConfig.map((config) => {
                  if(config.value === 'Actions'){
                      return <></>
                  }
                  return (
                    <div className="flex" key={config.key}>
                      <span className="text-sm font-light text-gray-700">{`${config.value}:`}</span>
                      <span className="text-sm font-normal text-gray-900 truncate ml-1">
                        {headerDataMapping[config.key] || ''}
                      </span>
                    </div>
                  )
              })}      
          </div>
          <div className="flex justify-end gap-x-4 mt-2">
             <ActionsPanel pid={pid} subject={subject} report_id={report_id} videoLink={ts} />
          </div>
      </div>
      {isOpen && <ExpandedDetails pid={pid} isOpen={isOpen} />}
    </div>
  );
};

// Sub-component for the desktop view
const DesktopView = ({ ext_ref, subject, tenant_name, inspection_type, report_date , report_id, pid, toggle, isOpen, ts }) => {

  return (
    <>
      <tr key={pid} className="cursor-pointer" onClick={toggle}>
        <td className="p-4 text-sm font-medium border-b border-gray-100 max-w-in150 truncate" title={ext_ref}>{ext_ref}</td>
        <td className="p-4 text-sm border-b border-gray-100 max-w-in300 in-lg:max-w-in150 truncate" title={subject}>{subject}</td>
        <td className="p-4 text-sm border-b border-gray-100 max-w-in150 truncate" title={tenant_name}>{tenant_name}</td>
        <td className="p-4 text-sm border-b border-gray-100">{inspection_type}</td>
        <td className="p-4 text-sm border-b border-gray-100">
          <div className="flex gap-x-1 items-center">
            <span>{report_date && dateTimeFormater(report_date, 'yyyy/mm/dd')}</span>
          </div>
        </td>
        <td className="p-4 text-sm border-b border-gray-100">
          <div className="flex gap-x-4">
             <ActionsPanel pid={pid} subject={subject} report_id={report_id} videoLink={ts} />
          </div>
        </td>
      </tr>
      {isOpen && <ExpandedDetails pid={pid} isOpen={isOpen} />}
    </>
  );
};

// Main component
const Reports = (props) => {
  const [notifState, setNotifState] = useState(null);
  const [isOpen, toggle] = useOpenController(false);
  const { isMobile } = useSelector((state) => state.config);



  return (
    <>
      {isMobile ? (
        <MobileView {...props}  isOpen={isOpen} toggle={toggle} />
      ) : (
        <DesktopView {...props} isOpen={isOpen} toggle={toggle} />
      )}
      {notifState && (
        <SnackBar
          open={Boolean(notifState)}
          handleClose={() => setNotifState(null)}
          severity={SeverityLevels.SUCCESS}
          message={notifState}
          duration={6000}
        />
      )}
    </>
  );
};

export default Reports;
  
