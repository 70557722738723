import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { fetchTableData, setPage, setSearchValue } from '../../../../store/slices/dashboardSlice'; 
import useDebounce from '../../../../hooks/useDebounce'; 

const SearchInput = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const dispatch = useDispatch();
    
    const { page, pageSize, filters,categoryFilter } = useSelector(state => state.dashboard); 
    const debouncedPage = useDebounce(page, 500)

    const previousSearchTerm = useRef('')

    useEffect(() => {
        // Check if the searchTerm is getting shorter (indicating a delete)
        const isDeleting = searchTerm.length < previousSearchTerm.current.length;
        previousSearchTerm.current = searchTerm;

        if (isDeleting || debouncedSearchTerm !== "") {
            dispatch(fetchTableData({
                lowerLimit: (debouncedPage - 1) * pageSize,
                upperLimit: debouncedPage * pageSize,
                filters,
                searchValue: debouncedSearchTerm,
                sortDirection: false,
                sortColumn: 0,
            }));
            dispatch(setPage(1));
            dispatch(setSearchValue(debouncedSearchTerm));
        }
    
    }, [debouncedSearchTerm]);


    useEffect(() => {
        setSearchTerm("")
        previousSearchTerm.current = ""
    }, [categoryFilter]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <>
            <div className="flex h-10 w-full max-w-sm">
                <label htmlFor="search-field" className="sr-only">
                    Search
                </label>
                <div className="relative bg-gray-100 lg:w-80 rounded-lg w-full">
                    <MagnifyingGlassIcon aria-hidden="true" className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500 ml-2" />
                    <input
                        id="search-field"
                        name="search"
                        type="search"
                        placeholder="Search anything here"
                        className="block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 focus:ring-0 sm:text-sm"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>
            </div>
        </>
    );
}

export default SearchInput;