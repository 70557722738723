import React, {useState, useEffect, useRef} from "react";
import { useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

//styles
import "../management/management.css";
import "./client_scans_dashboard.css";
//components
import Pagination from "../../components/pagination/pagination";
import Btn from "../../components/buttons/standard/btn";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import EmptyState from "../../components/emptyState/empty_state";
import Notification from "../../components/side_notification/side_notification";
import MasterPopup from "../../components/popups/main_popup";
import AllReports from "../../components/popups/all_reports/all_reports";
import { SimpleDropdownContainer, SimpleInputDropdown } from "../../components/dropdowns/simple_dropdown/simple_dropdown";
import ViewBaseline from "../../components/popups/view_baseline/view_baseline";
import SearchFilter from "../../components/popups/search_filter/scan_search_filters";
//utils
import { dateTimeFormater, isToday } from "../../utils/date_utils.js";
import { postReqOptBuilder, createDictFromHashedUrl } from "../../utils/main_utils";
//assets
import smallSearch from"../../assets/icons/search-small.svg"
import searchUp from "../../assets/icons/search-up.svg";
import searchDown from "../../assets/icons/search-down.svg";
import searchDownSelect from "../../assets/icons/search-down-select.svg";
import searchUpSelect from "../../assets/icons/search-up-select.svg";
import dots from "../../assets/icons/dots.svg";
//constants
const itemsPerPage = 20;

export default function ClientScanDashboard (props) {
    const cred = useSelector((state) => state.auth.cred);

    const cid = cred?.cid ?? null;
   
    // state based variables
    const [isLoader, setLoader] = useState(true);
    const [upcomingCheckouts, setUpcomingCheckouts] = useState();
    const [sortedScans, setSortedScans] = useState();
    const [scanPerformers, setScanPerformers] = useState(null);
    const [currentSort, setCurrentSort] = useState({"column": null, "direction": null}); //sort direction(true ASC, false DESC)
    const [currentSearchFilters, setCurrentSearchFilters] = useState({
        'scanDate': {'opt': null},
        'scan-type': {'opt': null},
        'scan-status': {'opt': null},
        'selected_properties': [],
        'selected_performers': [],
    });
    const [activePms, setActivePms] = useState(false)
    const [activeDD, setActiveDD] = useState(null);
    const [maxItems, setMaxItems] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMainEmptyState, setMainEmptyState] = useState(false);
    const [notifState, setNotifState] = useState(null);
    const [popupState, setPopupState] = useState(null);
    const [hashParams, setHashParams] = useState({})
    // general
    const location = useLocation();

    //load of initial data
    useEffect(() => {
        if (!document.body.onhashchange) document.body.onhashchange = handleHashUrl;
        let body = {
            "cid": cid,
            "upperLimit": itemsPerPage
        };
        //if prev location is last report, set body according to previous state
        const historyState = window.history.state;
        console.log(historyState);
        if (historyState != null && historyState.state != null && historyState.state['from'].startsWith('/report/')) {
            const state = historyState.state;
            //unpack saved state
            if (state.sortColumn) {
                body["sortColumn"] = state.sortColumn;
                body["sortDirection"] = state.sortDirection;
                setCurrentSort({"column": state.sortColumn, "direction": state.sortDirection});
            }
            if (state.searchFilters) {
                body['filters'] = state.searchFilters;
                setCurrentSearchFilters(state.searchFilters)
            }
            if (state.page) {
                body['page'] = state.page;
                body["lowerLimit"] = (state.page - 1) * itemsPerPage;
                body["upperLimit"] = state.page * itemsPerPage;
                setCurrentPage(state.page);
            }
            window.history.replaceState(null,null,null);
        }
        fetchScans(body);
        if (location.state && location.state.isAllReports) {
            //return allReports modal
            handleAllReports(location.state.pid, location.state.subject);
        }

        fetch(props.para_be + '/settings/get_pms_creds', {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            if (response.status === 200){
                if (response.result !== null && Object.keys(response.result).length > 0) setActivePms(true);
            } else {
                setNotifState({text: "Failed to verify PMS.", type: "error"});
            }
            const hashString = window.location.hash;
            if (hashString) setHashParams(createDictFromHashedUrl(hashString));
        })
        .catch(error => {
            console.log(error);
        });

        fetchCheckouts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleHashUrl = () => {
        console.log("Handling hash change in url");
        const hashDict = createDictFromHashedUrl(window.location.hash);
        console.log("Hash:", hashDict);
        if (Object.keys(hashDict).length > 0) {
            console.log("Updating hash params");
            setHashParams(hashDict);
            handleChangePage(1);
        }
    };

    const handleSortScans = (index) => {
        let sortColumn = [1, 2, 3].includes(index) ? index : null;
        let sortDirection = currentSort.column !== index ? false : !currentSort.direction;

        fetchScans({"cid": cid, "sortColumn": sortColumn, "sortDirection": sortDirection, "upperLimit": itemsPerPage, "filters": currentSearchFilters});
        setCurrentPage(1);
        setCurrentSort({"column": index, "direction": sortDirection});
    };

    const handleChangePage = (page) => {
        fetchScans({"cid": cid, "lowerLimit": (page-1)*itemsPerPage, 'upperLimit': page * itemsPerPage, "sortColumn": currentSort.column, "sortDirection": currentSort.direction, "filters": currentSearchFilters});
        setCurrentPage(page);
    };

    const fetchCheckouts = (view_type) => {
        // view_type: 0 - today, 1 - extended
        let sevenDaysAgo = new Date();
        let sevenDaysAhead = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        sevenDaysAhead.setDate(sevenDaysAhead.getDate() + 7);
        fetch(
            props.para_be + "/units/listUpcomingInspections" + 
            (view_type === 1 ? 
                ("?start_date=" + dateTimeFormater(sevenDaysAgo, "yyyy-mm-dd") + 
                "&end_date=" + dateTimeFormater(sevenDaysAhead, "yyyy-mm-dd"))
                : ""
            ), {credentials: "include"}
        )
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setUpcomingCheckouts(response.result);
            } else {
                setNotifState({text: "Failed to fetch upcoming checkouts.", type: "error"});
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState({text: "Failed to fetch upcoming checkouts.", type: "error"});
        })
    }

    const fetchScans = (body) => {
        setLoader(true);
        let q = "?";
        for (let i of Object.keys(body)) {
            if (body[i] != null) q += (i + "=" + (i === "filters" ? JSON.stringify(body[i]) : body[i]) + "&");
        }
        q = q.slice(0, -1);
        fetch(props.para_be + '/scan/listClientScans' + q, {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                if (Array.isArray(response.result.data) && response.result.data.length > 0) {
                    setSortedScans(response.result.data.slice(0));
                    if (isMainEmptyState) setMainEmptyState(false);
                } else {
                    setMainEmptyState(true);
                }
                if (response.result.maxRows !== null && response.result.maxRows !== maxItems) {
                    setMaxItems(response.result.maxRows);
                }
            } else {
                setNotifState({text: response.msg, type: "error"});
            }
            setLoader(false);
        })
        .catch (error => {
            setMainEmptyState(true);
            setNotifState({text: "Can't fetch data", type: "error"});
            setLoader(false);
        });
    };

    useEffect(() => {
        if (sortedScans && !scanPerformers) {
            setScanPerformers(
                Object.entries(sortedScans.reduce( (d,v) => ({...d, [v[4]]: v[5]}), {} )).map( (i) => ({name: i[1], id: i[0]}) )
            )
            // setScanPerformers(sortedScans.map( (item) => { return {name: item[5], id: item[4]} }));
        }
    }, [sortedScans]);

    useEffect(() => {
        if (activeDD) {
            document.body.addEventListener('click' , closeDD);
            return () => {document.body.removeEventListener('click' , closeDD)};
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDD]);

    const handleDropDown = (event, t) => {
        event.stopPropagation();
        if (activeDD === t) closeDD();
        else {
            if (activeDD) closeDD();
            setActiveDD(t);
        }
    };

    useEffect(()=> {
        closeDD();
    }, [popupState])

    const closeDD = () => {
        if (activeDD) setActiveDD(null);
    };

    const handleAllReports = (pid, subject) => {
        setPopupState([<AllReports
                            closeFunc={handleClosePopup}
                            para_be={props.para_be}
                            pid={pid}
                            subject={subject}
                            sortColumn={currentSort.column}
                            sortDirection={currentSort.direction}
                            filters={currentSearchFilters}
                            page={currentPage}/>, 
                        {extraClasses: "non-padded-popup", closeFunc: handleClosePopup}]);
        return false;
    };

    const handleOpenBaselineVideo = (target_pid, target_subject) => {
        setPopupState([<ViewBaseline closeFunc={handleClosePopup} para_be={props.para_be} pid={target_pid} subject={target_subject}/>, {closeFunc: handleClosePopup, extraClasses: "has-bsp-container"}]);
    };

    const handleOpenSearchFilterPopup = () => {
        setPopupState([
            <SearchFilter 
                closeFunc={handleClosePopup} 
                para_be={props.para_be} 
                activePMS={activePms} 
                currentFilters={currentSearchFilters} 
                employees={scanPerformers}
                applyChanges={ (newFilters) => { setCurrentSearchFilters(newFilters); }} 
            />, 
            {extraClasses: "non-padded-popup", closeFunc: handleClosePopup}
        ]);
    }

    const handleClosePopup = (refresh=false) => {
        setPopupState(null);
        if (refresh) handleChangePage(currentPage);
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    return (
        <section className="mgmt-main scans-dashboard">
            <Helmet>
                <title>Paraspot | Scans Dashboard</title>
                <meta name="description" content="."/>
                <meta property="og:title" content="Paraspot | Scans Dashboard"/>
                <meta property="og:description" content="."/>
            </Helmet>
            <div className="overview-section flexRow">
                <div className="shadow-box-1 pad-20-all upc-inspections-box">
                    <div className="h3 blue-headline mb-16">Upcoming Inspections</div>
                    {upcomingCheckouts && upcomingCheckouts.length > 0 ?
                        <table className="checkouts-today">
                            <tbody>
                                {upcomingCheckouts.map( (coItem) => {
                                    return  <tr className="mb-8">
                                                <td className="text-1-2 mr-16 ta-bottom clickable text-ellipsis max-w-in150" title={coItem.unit} onClick={() => { window.location.href = "/units#subject=" + coItem.unit; }}>{coItem.unit}</td>
                                                <td className="text-2 mr-16 ta-bottom text-ellipsis" title={coItem.guest}>{coItem.guest}</td>
                                                <td className="text-2 mr-16 ta-bottom">{coItem.inspection_datetime}</td>
                                                <td>
                                                    {coItem.status ?
                                                        <div className={"miss-baseline-label para-label " + (coItem.status === 'Done' ? 'para-l-pass' : (coItem.status === 'Missing' ? 'para-l-fail' : 'para-l-info'))}>{coItem.status}</div> :
                                                        <div className="miss-baseline-label para-label para-l-fail">Missing</div>
                                                    }
                                                </td>
                                            </tr>
                                })

                                }
                            </tbody>
                        </table>
                        :
                        <EmptyState text={"No checkouts today"} size="lg" nonAbs={true}/>
                    }
                </div>
            </div>

            <div className="mgmt-header flexColumn">
                <div className ="mgmt-sort ml-auto mobile-only">
                    <SimpleInputDropdown
                        placeholder="Sort by"
                        keepPlaceholder={true}
                        selectorClasses="pad-5-ud"
                        borderedItems={true}
                        onclick={(item) => handleSortScans(item === 'performer' ? 1 : item === 'unit' ? 2 : 3)}
                        items={[['performer', 'Performer'], ['unit', 'Unit'], ['started_at', 'Started At']].reduce((dict, [k, v], idx) => {
                            let isCurrentSort = currentSort.column === idx+1;
                            return {[k]: {'present': v, 'disabled': false, 'preItemText': 
                                            <div className="sort-icons flexColumn">
                                                <img src={(isCurrentSort && currentSort.direction === true) ? searchUpSelect : searchUp} alt="arrow-up"/>
                                                <img src={(isCurrentSort && currentSort.direction === false) ? searchDownSelect : searchDown} alt="arrow-down"/>
                                            </div>}, ...dict}
                        }, {})}/>
                </div>
                <div className="mgmt-header-btns flexRow">
                    <Btn 
                        text="Filter"
                        type="secondary"
                        onclick={() => handleOpenSearchFilterPopup()}/>
                </div>
            </div>

            <div className="mgmt-table">
                <div className="top-pg-container">
                    <div>{currentPage < 2 ? 1 : (((currentPage-1)*itemsPerPage) + 1)}-{itemsPerPage*currentPage >= maxItems ? maxItems : itemsPerPage*currentPage} of {maxItems} Scans</div>
                </div>
                <table cellSpacing="0" cellPadding="0">
                    <thead className="hide-mobile">
                        <tr>
                            {Object.entries({'Performer': {'sortable': true, 'className': 'th-performer'}, 'Unit': {'sortable': true, 'className': 'th-subject'}, 'Started At': {'sortable': true, 'className': 'th-start'}, 'Scan Type': {'sortable': false, 'className': 'th-scan_type'}, 'Status': {'sortable': false, 'className': 'th-status'}, 'Action': {'sortable': false, 'className': 'th-action'}}).map(([k, v], index) => {
                                let isCurrentSort = currentSort.column === index+1 && v['sortable'];
                                return (
                                    <th className={v['className'] + (isCurrentSort ? " sort-active" : "")} style={k === "Action" ? {width: 'unset'} : {}}>
                                        <div {...(v['sortable'] ? {onClick: () => handleSortScans(index+1)} : {})}>
                                            <span>{k}</span>
                                            {v['sortable'] &&
                                                <div className="sort-icons flexColumn">
                                                    <img src={isCurrentSort && currentSort.direction === true ? searchUpSelect : searchUp} alt="arrow-up"/>
                                                    <img src={isCurrentSort && currentSort.direction === false ? searchDownSelect : searchDown} alt="arrow-down"/>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                )})
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader ? <MainLoader/> :
                            isMainEmptyState ? <EmptyState text={"No data, add new item"}  size="lg"/> :
                            sortedScans?.map(function (item, index) {
                                //data: 0-SESS_ID, 1-PID, 2-subject, 3-Unit, 4-Performer ID, 5-Performer name&position, 6-Scan Type, 7-Status, 8-Started At, 9-Metadata
                                let dateFormat = isToday(item[8]) ? "HH:MM" : "dd/mm/yyyy HH:MM";
                                let datetTimeToPrint = dateTimeFormater(item[8], dateFormat);
                                const item_id = "mgmt_item_" + index;
                                return (
                                    <>
                                        <tr id={item_id} key={item_id}>
                                            <td className={"capitalizeText" + (currentSort.column === 1 ? " sort-active" : "")} data-th="Performer">{item[5] ? item[5] : item[4] ? item[4] : "Paraspot Admin"}</td>
                                            <td className={currentSort.column === 2 ? "sort-active" : ""} data-th="Unit" title={item[3]}>{item[3]}</td>
                                            <td className={currentSort.column === 3 ? "sort-active" : ""} data-th="Started At">{datetTimeToPrint}</td>
                                            <td data-th="Scan Type" className="capitalizeText">{item[6]}</td>
                                            <td data-th="Status">
                                                <div className={"miss-baseline-label para-label " + (item[7] === "Done" ? "para-l-pass" : "para-l-info")}>
                                                    {item[7]}
                                                </div>
                                                {/* {item[7] === "Done" ?
                                                    <div className="miss-baseline-label para-label para-l-pass">{item[7]}</div> : 
                                                    <div className="miss-baseline-label para-label para-l-info">Ongoing</div>
                                                } */}
                                            </td>
                                            <td>
                                                <div className="report-btns">
                                                    <SimpleDropdownContainer
                                                        extraClasses="more-items"
                                                        showDropdown={activeDD === item[0]}
                                                        borderedItems={true}
                                                        items={{
                                                            'show_baseline': {'present': 'Show Baseline Scan', 'disabled': false, 'onclick': (k, e) => handleOpenBaselineVideo(item[1], item[2])},
                                                            'all_reports': {'present': 'All Reports', 'disabled': false, 'onclick': (k, e) => handleAllReports(item[1], item[2])},
                                                        }}>
                                                        <img src={dots} alt="more-btn" onClick={(e) => handleDropDown(e, item[0])}/>
                                                    </SimpleDropdownContainer>
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
                {isMainEmptyState ? "" :
                    <Pagination
                        currentPage = {currentPage}
                        maxPages = {Math.ceil(maxItems / itemsPerPage)}
                        handleChangePage = {(page) => handleChangePage(page)}
                        setNotifState = {setNotifState}
                        itemsPerPage = {itemsPerPage}
                    />
                }
            </div>
            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}/> : ""
            }
            {popupState ? <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup> : ""}
        </section>
    )
}