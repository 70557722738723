import React, { useState, useMemo } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../../../axios/axios';
import { setPage, closeVideoModal, closeUploaderModal, closeApproveModal, 
        closeRejectModal,closeAllReportsModal, fetchTableData } from '../../../../store/slices/dashboardSlice';
import DataRow from './components/DataRow';
import Pagination from './components/Pagination/Pagination';
import ThreeDotsLoader from '../../../../components/loaders/ThreeDotsLoader';
import SnackBar from '../../../../components/SnackBar';
import ApproveModal from './components/Modals/ApproveModal';
import RejectApproveModal from './components/Modals/RejectApprovalModal';
import AllReports from './components/Modals/AllReports';
import VideoUploaderModal from './components/Modals/VideoUploaderModal';
import VideoModal from './components/Modals/VideoModal';
import EmptyState from '../EmptyState';
import SortSelect from './components/SortSelect'
import styles from './table-widget.module.css';
import { para_be } from '../../../../config';
import { moveInHeaderConfig,moveOutHeaderConfig, scansHeaderConfig, 
         reportsHeaderConfig, CommonHeader } from './config/table.config';

import { SeverityLevels,CategoryFilter } from '../../../../constants';

// Helper function to select the header configuration based on the category
const selectHeaderConfig = (categoryFilter) => {
  switch (categoryFilter) {
    case CategoryFilter.MOVE_IN:
      return moveInHeaderConfig;
    case CategoryFilter.MOVE_OUT:
      return moveOutHeaderConfig;
    case CategoryFilter.REPORTS:
      return reportsHeaderConfig;
    case CategoryFilter.SCANS:
      return scansHeaderConfig;
    default:
      return [];
  }
};

// Desktop view component
const DesktopTable = ({ data, loading, headerConfig, currentSortCol, sortColmn }) => {

  

  return (
    <table className={styles.table}>
      <thead className="bg-white cursor-default">
        <tr>
          {headerConfig.map((header,index) => (
            <th
              key={header.key}
              className="text-left text-sm font-semibold text-gray-900 cursor-pointer p-3"
              onClick={() => sortColmn(index, !currentSortCol || currentSortCol[0] !== index ? false : !currentSortCol[1])}
            >
              <div className="flex items-center justify-between">
                <span>{header.value}</span>
                {header?.sortable && 
                  <ChevronDownIcon 
                    className={
                      `w-4 h-4 cursor-pointer transition-transform transform hover:text-gray-600 hover:scale-110 ${currentSortCol && currentSortCol[0] === index ? 'text-blue-400' : 'text-gray-400'}${currentSortCol && currentSortCol[0] === index && currentSortCol[1] ? ' rotate-180' : ''}`
                    }
                  />
                }
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data && !loading ? (
          data.map((rowData, index) => (
            <DataRow key={index} {...rowData} />
          ))
        ) : (
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
               <ThreeDotsLoader height="40" width="40" color="#1890ff" radius="9" />
            </td>
            <td></td>
            <td></td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

// Mobile view component
const MobileTable = ({ data, loading, headerConfig, sortColmn }) => {

  const filterdheaderConfig = headerConfig.filter(obj => obj.value !== CommonHeader.ACTION)

  return (
    <ul className="block w-full">
      <div className='p-4'>
         <SortSelect options={filterdheaderConfig} sortColmn={sortColmn} />
      </div>

      {data && !loading ? (
        data.map((rowData, index) => (
          <DataRow key={index} {...rowData} />
        ))
      ) : (
        <li className="flex justify-center items-center">
          <ThreeDotsLoader height="40" width="40" color="#1890ff" radius="9" />
        </li>
      )}
    </ul>
  );
};

const TableWidget = () => {
  const dispatch = useDispatch();

  const { isMobile } = useSelector(state => state.config);
  const { data ,page, pageSize,  categoryFilter, loading, totalItems, searchValue, filters } = useSelector(state => state.dashboard);  
  const { isVideoModalOpen, isUploaderModalOpen, isApproveModalOpen, 
          isRejectModal,isAllReports, pid, subject, rid, videoLink } = useSelector(state => state.dashboard.selectedRowInTable);
  const [blockClosePopup, setBlockClosePopup] = useState(false);
  const [currentSortCol, setCurrentSortCol] = useState(null);
  const [notifState, setNotifState] = useState(null);
  const headerConfig = useMemo(() => selectHeaderConfig(categoryFilter), [categoryFilter]);


  const sortColmn = (index, sortDirection=false) =>{
    setCurrentSortCol([index, sortDirection]);
    const bodyForFetch = {
      lowerLimit: (page - 1) * pageSize,
      upperLimit: page * pageSize,
      filters,
      searchValue: searchValue,
      sortDirection: sortDirection,
      sortColumn: (index+1).toString(),
    };
    
    dispatch(fetchTableData(bodyForFetch))
 }

 
  const handleNext = () => {
    if (page < Math.ceil(totalItems / pageSize)) {
      dispatch(setPage(page + 1));
    }
  };

  
  const handlePrevious = () => {
    if (page > 1) {
      dispatch(setPage(page - 1));
    }
  };



  const handleSubmissionScan = async (selected_action = "approve", msg = null, should_send_msg = false) => {
    try {
      const response = await axios.post(
        `${para_be}/scan/${selected_action}_pending`,
        {
          rid: rid,
          ...(selected_action === 'approve' && should_send_msg ? { send_msg: "1" } : {}),
          ...(msg ? { msg: msg } : {})
        }, { withCredentials: true }
      );

      if (response.data.status === 200) {
        setNotifState({
          text: selected_action === "approve" ? "Submission approved successfully" : "Submissions rejected successfully",
          type: "success"
        });
      } else {
        setNotifState({
          text: selected_action === "approve" ? "Something went wrong while approving submission" : "Something went wrong while rejecting submission",
          type: "error"
        });
      }
    } catch (error) {
      setNotifState({
        text: selected_action === "approve" ? "Something went wrong while approving submission" : "Something went wrong while rejecting submission",
        type: "error"
      });
    } finally {
      if (selected_action === "approve") dispatch(closeApproveModal());
      else dispatch(closeRejectModal());
    }
    dispatch(setPage(page));
  };

  return (
    <>
      {/* Modals */}
      <VideoModal
        open={isVideoModalOpen}
        handleClose={() => dispatch(closeVideoModal())}
        pid={pid}
        subject={subject}
        videoLink={videoLink}
      />
      <VideoUploaderModal
        pid={pid}
        open={isUploaderModalOpen}
        handleClose={() => dispatch(closeUploaderModal())}
        changeBlockStatus={setBlockClosePopup}
        setNotifState={setNotifState}
      />
      <ApproveModal
        open={isApproveModalOpen}
        handleClose={() => dispatch(closeApproveModal())}
        onApprove={handleSubmissionScan}
      />
      <RejectApproveModal
        open={isRejectModal}
        handleClose={() => dispatch(closeRejectModal())}
        onRejection={handleSubmissionScan}
      />
      <AllReports 
        open={isAllReports}
        handleClose={() => dispatch(closeAllReportsModal())}
        pid={pid}
        subject={subject}
      />

      {/* Main Table Layout */}
      <>
        { data && data.length > 0  ? 
        <div className="border border-gray-200 rounded">
          <div className="whitespace-nowrap">
            <div className={`inline-block min-w-full align-middle ${styles.wrappedTable}`}>
              {isMobile ? (
                <MobileTable data={data} loading={loading} headerConfig={headerConfig} currentSortCol={currentSortCol} sortColmn={sortColmn} />
              ) : (
                <DesktopTable data={data} loading={loading} headerConfig={headerConfig} currentSortCol={currentSortCol} sortColmn={sortColmn} />
              )}
            </div>
          </div>
          <Pagination
            page={page}
            setPage={setPage}
            offset={1}
            limit={Math.ceil(totalItems / pageSize)}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        </div> : 
          <div className="flex justify-center items-center h-48 mb-8 border border-gray-200 rounded">
              <EmptyState 
                headerText="No Data Found" 
                decriptionText="No data available in the table" 
              /> 
          </div>
 
        }
        
      </>

      {/* Snackbar Notification */}
      {Boolean(notifState) && (
        <SnackBar
          open={Boolean(notifState)}
          handleClose={() => setNotifState(null)}
          severity={notifState.type === 'error' ? SeverityLevels.ERROR : SeverityLevels.SUCCESS}
          message={notifState.text}
          duration={6000}
        />
      )}
    </>
  );
};

export default TableWidget;