import dayjs from 'dayjs';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CategoryFilter } from '../../constants';
import axios from '../../axios/axios';
import { para_be } from '../../config';

// Thunk to fetch table data
export const fetchTableData = createAsyncThunk(
    'dashboard/fetchTableData',
    async ({ lowerLimit, upperLimit, filters, searchValue,sortDirection,sortColumn }, { rejectWithValue }) => {
        const queryString = `upperLimit=${upperLimit}&lowerLimit=${lowerLimit}&filters=${encodeURIComponent(JSON.stringify(filters))}&searchValue=${searchValue}&sortDirection=${sortDirection}&sortColumn=${sortColumn}`;
        try {
            const tableDataResponse = await axios.get(`${para_be}/dashboard/search?${queryString}`, { withCredentials: true });

            if (tableDataResponse.data.status >= 400) {
                return rejectWithValue(tableDataResponse.data.msg);
            }

            return {
                data: tableDataResponse.data, 
                lowerLimit, 
                upperLimit, 
                filters, 
                searchValue,
                sortDirection,
                sortColumn
            };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

// Thunk to fetch stats
export const fetchStats = createAsyncThunk(
    'dashboard/fetchStats',
    async ({ category, filters, searchValue }, { rejectWithValue }) => {
        try {
            const statsResponse = await axios.get(`${para_be}/dashboard/stats`, {
                params: {
                    category: category,
                    searchValue: searchValue,
                    filters: JSON.stringify(filters)
                },
                withCredentials: true
            });

            if (statsResponse.data.status >= 400) {
                return rejectWithValue(statsResponse.data.msg);
            }

            return statsResponse.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);


export const fetchAllCount = createAsyncThunk(
    'dashboard/fetchAllCount',
    async ({ lowerLimit, upperLimit, filters, searchValue,sortDirection,sortColumn }, { rejectWithValue }) => {

      const allCount = true;
      const queryString = `upperLimit=${upperLimit}&lowerLimit=${lowerLimit}&filters=${encodeURIComponent(JSON.stringify(filters))}&searchValue=${searchValue}&sortDirection=${sortDirection}&sortColumn=${sortColumn}&allCount=${allCount}`;
      
      try {
        const tableDataResponse = await axios.get(`${para_be}/dashboard/search?${queryString}`, { withCredentials: true });
  
        // Return only the serializable parts of the response (e.g., data)
        return tableDataResponse.data;  // Or tableDataResponse.data.rows if you need specific data
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Network error');
      }
    }
  );

const initialState = {
    data: [],         // Holds table data
    stats: [],        // Holds stats data
    page: 1,
    pageSize: 20,
    totalItems: 0,
    loading: false,   
    statsLoading: false, 
    error: null,      
    statsError: null, 
    categoryFilter: CategoryFilter.MOVE_OUT,
    lowerLimit: 0,
    upperLimit: 20,
    filters: {},
    sortDirection: false,
    sortColumn: 0,
    searchValue: '',
    dateDifference: [],
    tabIndex: "1",
    valueDateStart: null,
    valueDateEnd: null,
    selectedRowInTable:{
        pid: '',
        subject: '',
        rid: '',
        videoLink: '',
        isVideoModalOpen: false,
        isUploaderModalOpen: false,
        isApproveModalOpen: false,
        isRejectModal: false,
        isAllReports: false
    },
    allCount: null
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setPage(state, action) {
            state.page = action.payload;
        },
        setCategoryFilter(state, action) {
            state.categoryFilter = action.payload;
        },
        setFilters(state, action) {
            state.filters = action.payload;
        },
        setSearchValue(state, action) {
            state.searchValue = action.payload;
        },
        setDateDifference(state, action) {
            state.dateDifference = action.payload;
        },
        setTabIndex(state, action) {
            state.tabIndex = action.payload;
        },
        setValueDateStart(state, action) {
            state.valueDateStart = action.payload;
        },
        setValueDateEnd(state, action) {
            state.valueDateEnd = action.payload;
        },
        setLowerLimit(state, action) {
            state.lowerLimit = action.payload;
        },
        setUpperLimit(state, action) {
            state.upperLimit = action.payload;
        },
        openVideoModal(state, action) {
            state.selectedRowInTable.pid = action.payload.pid;
            state.selectedRowInTable.subject = action.payload.subject;
            state.selectedRowInTable.isVideoModalOpen = true;
            state.selectedRowInTable.videoLink = action.payload?.videoLink;
        },
        closeVideoModal(state) {
            state.selectedRowInTable.isVideoModalOpen = false;
            state.selectedRowInTable.pid = '';
            state.selectedRowInTable.subject = '';
            state.selectedRowInTable.videoLink = '';
        },
        openUploaderModal(state, action) {
            state.selectedRowInTable.pid = action.payload.pid;
            state.selectedRowInTable.isUploaderModalOpen = true;
        },
        closeUploaderModal(state) {
            state.selectedRowInTable.isUploaderModalOpen = false;
            state.selectedRowInTable.pid = '';
        },
        openApproveModal(state, action) {
            state.selectedRowInTable.rid = action.payload.rid;
            state.selectedRowInTable.isApproveModalOpen = true;
        },
        closeApproveModal(state) {
            state.selectedRowInTable.isApproveModalOpen = false;
            state.selectedRowInTable.rid = '';
        },
        openRejectModal(state, action) {
            state.selectedRowInTable.rid = action.payload.rid;
            state.selectedRowInTable.isRejectModal = true;
        },
        closeRejectModal(state) {
            state.selectedRowInTable.isRejectModal = false;
            state.selectedRowInTable.rid = '';
        },
        openAllReportsModal(state, action) {
            state.selectedRowInTable.pid = action.payload.pid;
            state.selectedRowInTable.subject = action.payload.subject;
            state.selectedRowInTable.isAllReports = true;
        },
        closeAllReportsModal(state) {
            state.selectedRowInTable.isAllReports = false;
            state.selectedRowInTable.pid = '';
            state.selectedRowInTable.subject = '';
        },
    
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTableData.fulfilled, (state, action) => {
                state.data = action.payload.data.data.rows;
                state.totalItems = action.payload.data.data.maxRows[0][0];
                state.loading = false;

                // Update the state with the parameters used in the request
                state.lowerLimit = action.payload.lowerLimit;
                state.upperLimit = action.payload.upperLimit;
                state.filters = action.payload.filters;
                state.searchValue = action.payload.searchValue;
                state.sortDirection = action.payload.sortDirection;
                state.sortColumn = action.payload.sortColumn;
            })
            .addCase(fetchTableData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong while fetching table data';
            })
            .addCase(fetchStats.pending, (state) => {
                state.statsLoading = true;
                state.statsError = null;
            })
            .addCase(fetchStats.fulfilled, (state, action) => {
                state.stats = action.payload.data; // Store the stats data
                state.statsLoading = false;
            })
            .addCase(fetchStats.rejected, (state, action) => {
                state.statsLoading = false;
                state.statsError = action.payload || 'Failed to fetch stats';
            })
            .addCase(fetchAllCount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllCount.fulfilled, (state, action) => {
                const moveIn = action.payload.data.rows[CategoryFilter.MOVE_IN] ? 
                action.payload.data.rows[CategoryFilter.MOVE_IN][0][0] : 0
                const moveOut = action.payload.data.rows[CategoryFilter.MOVE_OUT] ? 
                action.payload.data.rows[CategoryFilter.MOVE_OUT][0][0] : 0
                const reports = action.payload.data.rows[CategoryFilter.REPORTS] ? 
                action.payload.data.rows[CategoryFilter.REPORTS][0][0]: 0
                const scans = action.payload.data.rows[CategoryFilter.SCANS] ? 
                action.payload.data.rows[CategoryFilter.SCANS][0][0]: 0
                state.allCount = {
                    [CategoryFilter.MOVE_IN]:moveIn,
                    [CategoryFilter.MOVE_OUT]:moveOut,
                    [CategoryFilter.REPORTS]:reports,
                    [CategoryFilter.SCANS]:scans,
                }; // Store the stats data
                state.loading = false;
            })
            .addCase(fetchAllCount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const { setPage, setCategoryFilter, setLowerLimit, setUpperLimit, setFilters, 
    openVideoModal, closeVideoModal, openUploaderModal, closeUploaderModal,
    openApproveModal,closeApproveModal,openRejectModal, closeRejectModal,
    openAllReportsModal,closeAllReportsModal,setSearchValue,setDateDifference,
    setTabIndex,setValueDateStart,setValueDateEnd
} = dashboardSlice.actions;
export default dashboardSlice.reducer;