import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios/axios';
import { para_be } from '../../config';

const startLoading = state => {
    state.loading = true;
    state.error = null;
};

const loadingFailed = (state, action) => {
    state.loading = false;
    state.error = action.payload;
};

const loadingSuccess = payloadSetter => (state, action) => {
    state.loading = false;
    payloadSetter(state, action.payload);
};

// Async thunk for creating a customer
export const createCustomer = createAsyncThunk('customer/createCustomer', async (customerData, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${para_be}/payments/create_new_customer`, customerData, { withCredentials: true, });
        if (response.data.status >= 400) {
            return rejectWithValue(response.data.msg);
        }
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Async thunk for fetching customer data
export const fetchCustomerData = createAsyncThunk('customer/fetchCustomerData', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${para_be}/payments/get_customer_data`, { withCredentials: true });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const setCustomer = createAsyncThunk('customer/setCustomer', async ({ email, name, description }, { rejectWithValue }) => {
    try {
        const requestBody = {
            email,
            name,
            description,
        };

        const { data } = await axios.post(`${para_be}/payments/update_customer_details`, requestBody, { withCredentials: true, });
        if (data.status >= 400) {
            const cleanedMessage = data.msg.replace(/^Request\s+\w+:\s*/, '');
            return rejectWithValue(cleanedMessage);
        }
        return requestBody;
    } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
    }
});

export const removeCustomer = createAsyncThunk('customer/removeCustomer', async (_, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`${para_be}/payments/remove_account`, { withCredentials: true, });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Define the initial state of the customer slice
const initialState = {
    data: null,
    loading: false,
    error: null,
};

// Create the customer slice
const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchCustomerData.pending, startLoading)
        .addCase(
            fetchCustomerData.fulfilled,
            loadingSuccess((state, data) => (state.data = data))
        )
        .addCase(fetchCustomerData.rejected, loadingFailed)
        .addCase(createCustomer.pending, startLoading)
        .addCase(
            createCustomer.fulfilled,
            loadingSuccess((state, data) => (state.data = data))
        )
        .addCase(createCustomer.rejected, loadingFailed)
        .addCase(removeCustomer.pending, startLoading)
        .addCase(
            removeCustomer.fulfilled,
            loadingSuccess(state => (state.data = null))
        )
        .addCase(removeCustomer.rejected, loadingFailed)
        .addCase(setCustomer.pending, startLoading)
        .addCase(
            setCustomer.fulfilled,
            loadingSuccess((state, payload) => {
                const customer_data = state.data.customer_data;
                state.data.customer_data = { ...customer_data, ...payload };
            })
        )
        .addCase(setCustomer.rejected, loadingFailed);
    },
});

export const { clearError } = customerSlice.actions;
export default customerSlice.reducer;
