import BuildingIcon from '../icons/buildingIcon';
import UploadIcon from '../icons/uploadIcon';
import EyeIcon from '../icons/eyeIcon';
import styles from '../table-widget.module.css'

export const dropDownItemsConfig = [
  {
    label: 'View baseline',
    icon: <EyeIcon />
  },
  {
    label: 'upload move-out scan',
    icon: <UploadIcon />
  },
  {
    label: 'View unit',
    icon: <BuildingIcon />
  }
]


export const CommonHeader = {
  REFERNCE: 'Reference',
  UNITS: 'Units',
  TENANT_NAME: 'Tenant Name',
  ACTION: 'Actions'
};

export const moveOutHeaderConfig = [
  {
    key: '1',
    value: CommonHeader.REFERNCE,
    sortable: true
  },
  {
    key: '2',
    value: CommonHeader.UNITS,
    sortable: true
  },
  {
    key: '3',
    value: CommonHeader.TENANT_NAME,
    sortable: true
  },
  {
    key: '4',
    value: 'Move Out',
    sortable: true
  },
  {
    key: '5',
    value: 'Scan Status',
    sortable: true
  },
  {
    key: '6',
    value: CommonHeader.ACTION
  }
]


export const moveInHeaderConfig = [
  {
    key: '1',
    value: CommonHeader.REFERNCE,
    sortable: true
  },
  {
    key: '2',
    value: CommonHeader.UNITS,
    sortable: true
  },
  {
    key: '3',
    value: CommonHeader.TENANT_NAME,
    sortable: true
  },
  {
    key: '4',
    value: 'Move In',
    sortable: true
  },
  {
    key: '5',
    value: 'Scan Status',
    sortable: true
  },
  {
    key: '6',
    value: CommonHeader.ACTION
  }
]


export const scansHeaderConfig = [
  {
    key: '1',
    value: CommonHeader.REFERNCE,
    sortable: true
  },
  {
    key: '2',
    value: CommonHeader.UNITS,
    sortable: true
  },
  {
    key: '3',
    value: CommonHeader.TENANT_NAME,
    sortable: true
  },
  {
    key: '4',
    value: 'Scan Type',
    sortable: true
  },
  {
    key: '5',
    value: 'Date Scanned',
    sortable: true
  },
  {
    key: '6',
    value: CommonHeader.ACTION
  }
]


export const  reportsHeaderConfig = [
  {
    key: '1',
    value: CommonHeader.REFERNCE,
    sortable: true
  },
  {
    key: '2',
    value: CommonHeader.UNITS,
    sortable: true
  },
  {
    key: '3',
    value: CommonHeader.TENANT_NAME,
    sortable: true
  },
  {
    key: '4',
    value: 'Report Type',
    sortable: true
  },
  {
    key: '5',
    value: 'Report Date',
    sortable: true
  },
  {
    key: '6',
    value: CommonHeader.ACTION
  }
]

export const colorKeysConfig = {
  'done': 'text-green-500',
  'missing': 'text-red-500',
  'init': 'text-blue-500',
  'failed': styles.textOrange
}


  