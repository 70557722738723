import React from 'react';
import { Link } from 'react-router-dom';
import { openVideoModal, openAllReportsModal } from '../../../../../../../../../../store/slices/dashboardSlice';
import TableOptionsMenu from '../../../TableOptionsMenu';
import MaterialSymbols from '../../../../../../icons/materialSymbols';
import DocsIcon from '../../../../../../icons/docsIcon';
import FileStack from '../../../../../../icons/fileStack';




const OptionsMenu = React.memo(({ pid, subject }) => {
    const menuItems = [
      {
        label: 'All Reports',
        icon: FileStack,
        onClick: (dispatch) => dispatch(openAllReportsModal({ pid, subject })),
      },
      {
        label: 'View Baseline',
        icon: MaterialSymbols,
        onClick: (dispatch) => dispatch(openVideoModal({ pid, subject })), 
      },
    ];

    return (
      <TableOptionsMenu items={menuItems} />
    );
});


const ActionsPanel = ({pid,subject,report_id}) => {
  return (
    <>
      {report_id ? (
        <Link to={`/report/${report_id}`} className="cursor-pointer">
            <DocsIcon />
        </Link>
        ) : (
          <span className='cursor-default'>
            <DocsIcon disabled />
          </span>
        )}
        <OptionsMenu pid={pid} subject={subject} />
    </>
  )
}

export default ActionsPanel
