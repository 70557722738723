import React, {useState, useEffect, useRef} from "react";
import { Helmet } from 'react-helmet';

//style
import "./baseline_scan.css";
//components
import VideoStreamSocket from "../../components/scanner/socket/socket";
import SimpleLoader from "../../components/scanner/loader/simple-loader";
import Lobby from "../../components/scanner/foreplay/lobby";
import ScanStep from "../../components/scanner/foreplay/obi_one";
import ScanVideoStep from "../../components/scanner/foreplay/obi_one_vid";
import CamSettingsLoader from "../../components/scanner/foreplay/load_cam_settings";
import FeedbackBlock from "../../components/scanner/foreplay/feedback_chain";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import VideoCamera, { CAM_TYPE_TENANT_SCAN, CAM_TYPE_TENANT_SCAN_NO_BASE } from "../../components/scanner/camera/videoCamera";
import InteractiveVideoCameraV2 from "../../components/scanner/camera/interactiveVideoCameraV2";
import ScannerError from "../../components/errorMessages/scannerError/scannerError";
import VideoUploader from "../../components/uploadVideo";
import MasterPopup from "../../components/popups/main_popup";
import Btn from "../../components/buttons/standard/btn";
//containers
//assets
// import howToVid from "../../assets/scan_page/TenantScan-HowTo.mp4"
import howToVid from "../../assets/scan_page/TenantScan-HowToV2.mp4"
import step1x1 from "../../assets/scan_page/step1.jpg"
import step1x2 from "../../assets/scan_page/step1@2x.jpg"
import step1x3 from "../../assets/scan_page/step1@3x.jpg"
import step2x1 from "../../assets/scan_page/step2.jpg"
import step2x2 from "../../assets/scan_page/step2@2x.jpg"
import step2x3 from "../../assets/scan_page/step2@3x.jpg"
import step3x1 from "../../assets/scan_page/step3.jpg"
import step3x2 from "../../assets/scan_page/step3@2x.jpg"
import step3x3 from "../../assets/scan_page/step3@3x.jpg"
//utils
import { getClientData, isIOS, postReqOptBuilder } from "../../utils/main_utils";
import { blobToFile, fetchPresignedUrls, getFileExtFromBlob, uploadFile } from "../../utils/vid_upload_utils";
//constants
const SOCKET_NS = "test_socket";
const FPS = 20;
const CAMERA_CONSTRAINTS = {
    video: {
        facingMode: { exact: 'environment' },
        frameRate: { ideal: FPS },
        aspectRatio: 16/9,
        zoom: 1,
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        // ...(isIOS() ? {
        //     width: { ideal: 1920 },
        //     height: { ideal: 1080 },
        // } : {})
    },
    audio: false
};
const STEP_LOBBY = 0;
const STEP_VID = 6;
const STEP_EXP1 = 1;
const STEP_EXP2 = 2;
const STEP_EXP3 = 3;
const STEP_SCAN = 4;
const STEP_DONE = 5;
const STEP_FEEDBACK_1 = 7;
const STEP_FEEDBACK_2 = 8;
const STEP_RETRY_FILE_UPLOAD = 9;
const STEP_LOADING_ISSUE = 10;
const STEP_LOADING_ISSUE_RESOLVED = 11;
const STEP_UPLOAD_FAILED = 12;

export default function TenantScan (props) {
    const scanID = props.match.params.scan_id;
    const clientBeName = props.match.params.cbe_name;
    const inspection_type = props.inspection_type ? props.inspection_type : props.match.params.inspection_type;
    const base_url = props.base_url;
    const debugMode = (new URLSearchParams(window.location.search)).get('debug') === '1';
    const autoApply = (new URLSearchParams(window.location.search)).get('auto_apply');
    const redirectURL = (new URLSearchParams(window.location.search)).get('redirect_url');
    // state based variables
    const [loadingPage, setLoadingPage] = useState(true);
    const [lang, setLang] = useState(null);
    const [socket, setSocket] = useState(null);
    const [sockets, setSockets] = useState(null);
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const [finalizingScan, setFinalizingScan] = useState(false);
    const [loadedPercentage, setLoadedPercentage] = useState(0);
    const [videoDeviceDets, setVideoDeviceDets] = useState(null);
    const [step, setStep] = useState(0);
    const [shouldCompress, setShouldCompress] = useState(null);
    const [popupState, setPopupState] = useState(null);
    const [blockClosePopup, setBlockClosePopup] = useState(false);
    // video upload related
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [recordingUploadRate, setRecordingUploadRate] = useState(0);
    const [uploadLoaderStatus, setUploadLoaderStatus] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [fileUploadTimer, setFileUploadTimer] = useState(null);
    // Screen specific state variables
    const [endUserData, setendUserData] = useState(null);
    const [clientLogo, setClientLogo] = useState(null);
    const [pid, setPID] = useState(null);
    const [scanData, setScanData] = useState(null);
    const [clientFn, setClientFn] = useState(null);
    const [unitAddress, setUnitAddress] = useState(null);
    const [sockConnStatusState, setSockConnStatusState] = useState({});
    const [failedApiCalls, setFailedApiCalls] = useState(0);
    const [lastMass, setLastMass] = useState(null);
    const [remainingFrames, setRemainingFrames] = useState(0);
    // general variables
    let scanSess = useRef(null);
    let scannedFrames = useRef(0);
    let framesSent = useRef(0);
    let lp_frameIdx = useRef(0);
    let ignoredFrames = useRef(0);
    let framesCache = useRef({});
    let isDoneUploadingInterval = useRef(null);
    let lastReceive = useRef(null);
    let holdOff = useRef(null);
    let midScanDisconnection = useRef(false);
    let resendFramesInterval = useRef(null);
    let socketDisconnections = useRef(0);
    let lastDisconnect = useRef(null);
    let disconnectCounter = useRef(0);
    let sockConnReset = useRef(0);

    const switchStep = (next_step) => {
        console.log("Switching Step");
        let permitSwitch = false;
        if (next_step === STEP_DONE) {
            if (step === STEP_SCAN) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_FEEDBACK_1) {
            if (step === STEP_DONE) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_FEEDBACK_2) {
            if (step === STEP_FEEDBACK_1) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_LOADING_ISSUE) {
            if (finalizingScan && step !== STEP_FEEDBACK_1 && step !== STEP_FEEDBACK_2 && step !== STEP_DONE) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_RETRY_FILE_UPLOAD) {
            if (step === STEP_LOADING_ISSUE || step === STEP_UPLOAD_FAILED) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_UPLOAD_FAILED) {
            if (step === STEP_LOADING_ISSUE || step === STEP_RETRY_FILE_UPLOAD) {
                permitSwitch = true;
            }
        } else if (next_step === STEP_LOADING_ISSUE_RESOLVED) {
            if (step === STEP_LOADING_ISSUE) {
                permitSwitch = true;
            }
        } else {
            permitSwitch = true;
        }
        if (permitSwitch) {
            setStep(next_step);
        } else {
            console.log(`Switch step from ${step} to ${next_step} is not permitted`);
        }
    };

    const getFinalConstraints = () => {
        if (videoDeviceDets !== true && videoDeviceDets !== false) {
            return {
                video: {
                    ...CAMERA_CONSTRAINTS.video,
                    deviceId: videoDeviceDets.deviceId
                },
                audio: CAMERA_CONSTRAINTS.audio
            }
        } else return CAMERA_CONSTRAINTS
    }

    const switchToLoading = (sid, totalFrames, sentFrames, corrupted_blobs) => {
        if (!finalizingScan) setFinalizingScan(true);
        framesSent.current = sentFrames;
        scannedFrames.current = totalFrames;
        ignoredFrames.current = corrupted_blobs;
        setRemainingFrames(Object.keys(framesCache.current).length);
        updateLoadedPercentage();
        if (isDoneUploadingInterval.current === null) {
            isDoneUploadingInterval.current = setInterval(() => {
                if (step === STEP_DONE || step === STEP_FEEDBACK_1 || step === STEP_FEEDBACK_2) {
                    clearInterval(isDoneUploadingInterval.current);
                } else {
                    fetch(`https://aiv2.paraspot.ai/scan/isDoneUploading?sid=${sid}&frames_sent=${totalFrames-corrupted_blobs}`)
                    .then(response => response.json())
                    .then(response => {
                        if (response.status === 200 && response.result === true) {
                            switchStep(STEP_DONE);
                            setTimeout(() => { switchStep(STEP_FEEDBACK_1); }, 3000);
                            clearInterval(isDoneUploadingInterval.current);
                        } else if (response.status === 200 && response.hasOwnProperty('frames_received') && response.frames_received > lp_frameIdx.current) {
                            lp_frameIdx.current = response.frames_received;
                            updateLoadedPercentage();
                            lastReceive.current = new Date();
                            if (response.hasOwnProperty('frames_received_list')) {
                                for (let fr_idx of response.frames_received_list) {
                                    if (framesCache.current.hasOwnProperty(parseInt(fr_idx))) {
                                        delete framesCache.current[parseInt(fr_idx)];
                                        setRemainingFrames(Object.keys(framesCache.current).length);
                                    }
                                }
                            }
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
                }
            }, 15000);
        }
    };

    const updateLoadedPercentage = () => {
        if (step === STEP_DONE || step === STEP_FEEDBACK_1 || step === STEP_FEEDBACK_2) return;
        console.log("lp_frameIdx:", lp_frameIdx.current, 
                    "|\tframesSent:", framesSent.current, 
                    "|\tignoredFrames:", ignoredFrames.current, 
                    "|\tscannedFrames:", scannedFrames.current);
        let lp = Math.floor((lp_frameIdx.current+2+framesSent.current-ignoredFrames.current)/(scannedFrames.current*2)*100);
        console.log(`Loaded: ${lp}`);
        setLoadedPercentage(lp);
        if (lp === 100) {
            switchStep(STEP_DONE);
            setTimeout(() => {
                switchStep(STEP_FEEDBACK_1)
            }, 3000)
        }
    };

    const showError = (errMsg=true) => {
        setShowErrorScreen(errMsg);
    };

    const onSocketConnect = (sock_ref) => {
        setSockConnStatusState((o) => {
            return {
                ...o,
                [sock_ref]: true
            };
        });
    };
    
    const onSocketDisconnect = (sock_ref) => {
        setSockConnStatusState((o) => {
            return {
                ...o,
                [sock_ref]: false
            };
        });
        if (step === STEP_SCAN || (step === STEP_SCAN && finalizingScan)) {
            midScanDisconnection.current = true;
            socketDisconnections.current += 1;
        }
        if (sock_ref === 0) {
            lastDisconnect.current = new Date();
            if ((new Date()) - lastDisconnect.current > 8000) {
                disconnectCounter.current = 1;
            } else disconnectCounter.current += 1;

            if (disconnectCounter.current > 6) {
                console.log("Re-Establishing connection with socket");
                console.log("sess:", scanSess.current);
                console.log("scannedFrames:", scannedFrames.current);
                sockets.map((x) => {
                    x.close();
                    return null;
                });
                console.log("Initiating the multi sockets connection");
                VideoStreamSocket.initMultiSockets(SOCKET_NS, props.para_be)
                .then((out) => {
                    console.log("Setting sockets:", out);
                    setSockets(out);
                });
                sockConnReset.current += 1;
            }
        }
    };

    const reEstablishSocketConnection = () => {
        console.log("Re-Establishing connection with socket");
        setSockets(
            sockets.map((x) => {
                x.close();
                return null;
            })
        );
        console.log("Initiating the multi sockets connection");
        VideoStreamSocket.initMultiSockets(SOCKET_NS, props.para_be)
        .then((out) => {
            console.log("Setting sockets:", out);
            setSockets(out);
        });
    }

    const onFrameReceived = (frame_idx) => {
        lp_frameIdx.current++;
        delete framesCache.current[frame_idx];
        setRemainingFrames(Object.keys(framesCache.current).length);
        updateLoadedPercentage();
        lastReceive.current = new Date();
    };

    const onFramesResendReq = (sid, framesToResend, apiCall=false) => {
        if (sockets) {
            let i = 0;
            for (let frame_idx of framesToResend) {
                if (apiCall) {
                    console.log(`[${frame_idx}] Sending over API:\n`, framesCache.current[frame_idx]);
                    let d = new FormData();
                    d.append("sid", sid);
                    d.append("frame_idx", parseInt(frame_idx));
                    d.append("frame_data", framesCache.current[frame_idx]);
                    fetch(
                        "https://aiv2.paraspot.ai/scan/frame_processor", 
                        {
                            method: 'POST',
                            headers: {'withCredentials': true},
                            body: d,
                            credentials: 'include'
                        }
                    )
                    .then(response => {
                        if (response.status === 500) {
                            setFailedApiCalls(failedApiCalls+1);
                        }
                    })
                    .catch(err => {
                        console.log("Failed to send to frame_processor");
                        console.log(err);
                    });
                } else {
                    sockets[i].emit('frame_processor', sid, framesCache.current[frame_idx], parseInt(frame_idx));
                    if (sockets.length-1 === i) {
                        i = 0;
                    } else i++;
                }
            }
        } else {
            for (let frame_idx of framesToResend) {
                socket.emit('frame_processor', sid, framesCache.current[frame_idx], parseInt(frame_idx));
            }
        }
    }

    const onMassFramesResendReq = (sid, framesToResend) => {
        console.log(`[MASS] Sending over API:\n`, framesToResend);
        let framesOut = framesToResend.map( (frame_idx) => parseInt(frame_idx) )
        let d = new FormData();
        d.append("sid", sid);
        d.append("frame_idxs", framesOut);
        for (let i=0; i<framesOut.length; i++) {
            d.append(`frame_data${i}`, framesCache.current[framesOut[i]]);
        }
        fetch(
            "https://aiv2.paraspot.ai/scan/mass_frame_processor", 
            {
                method: 'POST',
                headers: {'withCredentials': true},
                body: d,
                credentials: 'include'
            }
        )
        .catch(err => {
            console.log("Failed to send to frame_processor");
            console.log(err);
        });
    }

    const seqMassFramesResend = (sid, framesToResend) => {
        console.log("Starting sequential mass resend...");
        const massSize = 10
        let targetFramesToResend = framesToResend.slice(0, massSize).map( (frame_idx) => parseInt(frame_idx) );
        console.log(`[MASS] Sending over API:\n`, targetFramesToResend);
        let d = new FormData();
        d.append("sid", sid);
        d.append("frame_idxs", targetFramesToResend);
        for (let i=0; i<targetFramesToResend.length; i++) {
            d.append(`frame_data${i}`, framesCache.current[targetFramesToResend[i]]);
        }
        fetch(
            "https://aiv2.paraspot.ai/scan/mass_frame_processor", 
            {
                method: 'POST',
                headers: {'withCredentials': true},
                body: d,
                credentials: 'include'
            }
        )
        .then(response => response.json())
        .then(response => {
            let framesToRecall = framesToResend.slice();
            if (response.status === 200) {
                if (response.success.every((x) => x === true)) {
                    framesToRecall = framesToRecall.slice(massSize);
                } else {
                    let failedFrames = [];
                    response.success.map((x, idx) => {
                        if (!x) failedFrames.push(targetFramesToResend[idx]);
                    });
                    framesToRecall = failedFrames.concat(framesToRecall.slice(massSize));
                }
            }
            if (framesToResend.length > massSize) seqMassFramesResend(sid, framesToResend.slice(massSize));
        })
        .catch(err => {
            console.log("Failed to send to frame_processor");
            console.log(err);
        });
    }

    const uploadRecording = (sid) => {
        if (uploadStatus === 'done') {
            console.log("Upload already done. Skipping...");
            return;
        } else if (uploadStatus === 'ongoing') {
            console.log("Upload is ongoing. Skipping...");
            return;
        }
        const lastStatus = uploadStatus ? (uploadStatus + "") : null;
        setUploadStatus('ongoing');
        let timeNow = new Date();
        timeNow.setMinutes(timeNow.getMinutes() + 7);
        setFileUploadTimer(timeNow);
        setTimeout(() => {
            if (step === STEP_LOADING_ISSUE || step === STEP_RETRY_FILE_UPLOAD) {
                switchStep(STEP_UPLOAD_FAILED);
            }
        }, 1000*60*7);
        // Uploading the recorded scan
        const fileToUpload = blobToFile(recordedBlob, sid);
        fetchPresignedUrls(props.para_be, fileToUpload, `${scanID}_${pid}`)
        .then(({ upload_id, presigned_urls }) => {
            uploadFile(
                props.para_be, fileToUpload, upload_id, presigned_urls, `${scanID}_${pid}`, 
                (uploadRate) => {
                    setRecordingUploadRate(uploadRate);
                    if (step === STEP_LOADING_ISSUE) switchStep(STEP_LOADING_ISSUE_RESOLVED);
                }, 
                setUploadLoaderStatus, null, 
                () => {
                    setUploadStatus('done');
                    switchStep(STEP_DONE);
                }, 
                () => {
                    setUploadStatus(lastStatus === 'fail' ? 'fail2' : 'fail');
                },
                () => {
                    if (step === STEP_LOADING_ISSUE) switchStep(STEP_RETRY_FILE_UPLOAD);
                }
            );
        })
        .catch((error) => {
            console.error("An error occurred while uploading recorded scan:", error);
            setUploadStatus(lastStatus === 'fail' ? 'fail2' : 'fail');
        });
    }

    const finalizeScan = (sid, intervalCount) => {
        // console.log("[Tenant Scan] Finalizing scan!");
        sockets[0].emit('finalize_scan', sid, intervalCount-2);
        setTimeout(() => {
            // console.log("[Finalizing scan] In timeout call");
            if (step !== STEP_DONE) {
                // console.log("[Finalizing scan] Step is not DONE");
                let intervalIdx = 0;
                resendFramesInterval.current = setInterval(
                    () => {
                        // console.log("[Finalizing scan] At interval call");
                        if (step === STEP_DONE || step === STEP_FEEDBACK_1 || step === STEP_FEEDBACK_2) {
                            // console.log("[Finalizing scan] Clearing interval");
                            clearInterval(resendFramesInterval.current);
                        }
                        let cachedFrameIdxs = Object.keys(framesCache.current);
                        if (cachedFrameIdxs.length > 0) {
                            if (new Date() - lastReceive.current > 20000) {
                                console.log("[INFO] Uploading video is stuck. Switching to loading issue mode");
                                // Uploading the recorded scan
                                uploadRecording(sid);
                                switchStep(STEP_LOADING_ISSUE);
                                clearInterval(resendFramesInterval.current);
                                return;
                            }
                            // console.log("[Finalizing scan] Calling resend");
                            if (sockConnReset.current > 0 || (new Date() - lastReceive.current) > 10000) {
                                if (lastMass === null) {
                                    seqMassFramesResend(sid, cachedFrameIdxs);
                                    setLastMass(new Date());
                                }
                            } else {
                                onFramesResendReq(
                                    sid, 
                                    (sockConnReset.current > 0 || new Date() - lastReceive.current > 50000) ? (
                                        (isIOS()) ? 
                                            cachedFrameIdxs.slice(intervalIdx*5, (intervalIdx*5)+5) : 
                                            cachedFrameIdxs.slice(intervalIdx*20, (intervalIdx*20)+20)) :    
                                    cachedFrameIdxs.length > 500 ? cachedFrameIdxs.slice(0, parseInt(cachedFrameIdxs.length / 10)) : cachedFrameIdxs,
                                    false
                                );
                                intervalIdx++;
                                if (intervalIdx*5 >= cachedFrameIdxs.length) {
                                    intervalIdx = 0;
                                }
                            }
                        }
                    }, 1000
                );
            }
        }, 5000);
    };

    const prepInstructions = (tmpScanData) => {
        if (typeof tmpScanData?.scan_data === "string") tmpScanData.scan_data = JSON.parse(tmpScanData.scan_data);
        if (typeof tmpScanData?.scan_metadata === "string") tmpScanData.scan_metadata = JSON.parse(tmpScanData.scan_metadata);
        if (typeof tmpScanData?.uni_frames === "string") tmpScanData.uni_frames = JSON.parse(tmpScanData.uni_frames);
        if (typeof tmpScanData?.uf_data === "string") tmpScanData.uf_data = JSON.parse(tmpScanData.uf_data);
        if (tmpScanData?.scan_metadata) tmpScanData['instructions'] = tmpScanData.scan_metadata.scan_session.finalInstructions;
        setScanData(tmpScanData);
    };

    const handleClosePopup = (refresh=false) => {
        if (!blockClosePopup) {
            setPopupState(null);
        }
    }

    const changeBlockStatus = (isBlocked) => {
        setBlockClosePopup(isBlocked);
    }

    const handleUploadVideo = (pid) => {
        setPopupState([
            <VideoUploader 
                customText={<>You experienced an issue with performing the scan?<br/>Click to upload a video of the scan</>} 
                pid={pid} 
                changeBlockStatus={changeBlockStatus} 
                closeFunc={handleClosePopup} 
                onUploadComplete={() => {
                    setTimeout(() => {
                        handleClosePopup();
                        setStep(STEP_DONE);
                    }, 3000);
                }}
                para_be={props.para_be} />, 
            {closeFunc: handleClosePopup, extraClasses: "has-bsp-container"}
        ]);
    }

    useEffect(() => {
        fetch(props.para_be + '/scan/authenticateScan', postReqOptBuilder({'scanID': scanID, 'clientBeName': clientBeName}))
            .then(response => response.json())
            .then(response => {
                console.log(response);
                if(response.status === 200) {
                    setPID(response.result.pid);
                    setClientLogo(response.result.logo);
                    setClientFn(response.result.name);
                    setUnitAddress(response.result.address);
                    console.log("Starting prep instructions");
                    prepInstructions(response.result);
                    setShouldCompress(response.result.shouldCompress);
                } else {
                    //TODO - set error
                    console.log("Error");
                }
                setLoadingPage(false);
            })
            .catch ( error => { //TODO - add error handling
                console.log("Exception was thrown while authenticating scan");
                console.log(error);
            });

        console.log("[i] Init socket");
        // setSocket(VideoStreamSocket.initSocket(SOCKET_NS));
        VideoStreamSocket.initMultiSockets(SOCKET_NS, props.para_be)
        .then((out) => {
            console.log("Setting sockets:", out);
            setSockets(out);
        });
        getClientData().then(data => {
            setendUserData(data);
        });
        window.onbeforeunload = function() {
            return "Data will be lost if you leave the page. Are you sure?";
        };        
    }, []);

    // useEffect(() => {
    //     VideoStreamSocket.initSocketEventListeners(socket, true, true, true, false, false, null, null, onFrameReceived, null, null);

    //     return () => {
    //         if (socket) {
    //             console.log("[i] Closing socket...");
    //             socket.close();
    //         }
    //     }
    // }, [socket]);

    useEffect(() => {
        console.log("Sockets:\n", sockets);
        if (sockets) {
            // for (let s of sockets) {
            for (let s_idx=0; s_idx<sockets.length; s_idx++) {
                let s = sockets[s_idx];
                console.log("Init socket even listeners");
                // VideoStreamSocket.initSocketEventListeners(s, 
                VideoStreamSocket.initSocketEventListeners(s, s_idx, 
                    // socketConnect, socketDisconnect, frameReceived, frameDone, scanResults, framesResendRequest
                    true, true, true, false, false, true, 
                    onSocketConnect, onSocketDisconnect, onFrameReceived, null, null, onFramesResendReq);
            }
        }

        return () => {
            if (sockets) {
                console.log("[i] Closing socket[s]...");
                for (let s of sockets) {
                    s.close();
                }
            }
        }
    }, [sockets]);

    useEffect(() => {
        console.log("scanData has changed");
        console.log(scanData);
        console.log(!scanData || !scanData.instructions || !scanData.uni_frames);
        // if (!scanData || !scanData.instructions || !scanData.uni_frames) {
        if (!scanData) {
            console.log("Showing Error...");
            showError();
        } else setShowErrorScreen(false);
    }, [scanData]);

    useEffect(() => {
        if (videoDeviceDets === false) {
            setShowErrorScreen("Cannot perform scan on this device. There is no back facing camera available.");
        }
    }, [videoDeviceDets]);

    useEffect(() => {
        if (uploadStatus === "fail") {
            uploadRecording(scanSess.current);
        }
    }, [uploadStatus]);


    return (
        <section className={`t-scan-main`}>
            <Helmet>
                <title>Checkout {unitAddress || ""} | {clientFn || ""} | Paraspot</title>
                <meta name="description" content={`Perform contactless checkout from ${unitAddress} using our AI scan.`}/>
                <meta property="og:title" content={`Checkout ${unitAddress} | ${clientFn} | Paraspot`}/>
                <meta property="og:description" content={`Perform contactless checkout from ${unitAddress} using our AI scan.`}/>
            </Helmet>

            {debugMode &&
                <div className="debug-layer">
                    <div className="sockets-view">
                        <div className="h3">Sockets</div>
                        {
                            Object.keys(sockConnStatusState).map((k) => {
                                return (
                                    <div className="flexRow text-2">
                                        <div>Sock {k}:</div>
                                        <div>{sockConnStatusState[k] ? "Connected" : "Disconnected"}</div>
                                    </div>
                                );
                            })
                        }
                        <p className="text-3">
                            Last Received: {lastReceive.current ? ((new Date() - lastReceive.current)/1000) : "?"}/s
                            <br/>
                            Remaining Frames: {framesCache.current ? remainingFrames : "N/A"}
                            <br/>
                            Last Mass Resend: {lastMass ? Math.round((new Date() - lastMass)/1000) : "?"}/s
                        </p>
                    </div>
                </div>
            }

            {
                loadingPage ?
                    <MainLoader/> :
                !pid ?
                    <ScannerError errMsg={<>Error!<br/>Invalid scan ID or client name</>}/> :
                showErrorScreen !== false ?
                    <ScannerError errMsg={showErrorScreen === true ? undefined : showErrorScreen}/> :
                step === STEP_LOBBY ?
                    <Lobby
                        includeClientLogo={true}
                        clientLogo={clientLogo && clientLogo.includes("https://") ? clientLogo : `${base_url}/${clientLogo}`}
                        onNext={(selectedLang) => {
                                setLang(selectedLang);
                                switchStep(STEP_VID);
                            }}
                        btnText="Next"
                        langSelection={true}
                        onHelp={() => handleUploadVideo(`${scanID}_${pid}`)}
                    /> :
                    // img, text, btnText, onSkip, onBtnClick, step, totalSteps
                step === STEP_VID && videoDeviceDets === null ?
                    <CamSettingsLoader onFinishedLoading={setVideoDeviceDets} /> :
                step === STEP_VID ?
                    <ScanVideoStep
                        vid={howToVid}
                        text={
                            lang === "es" ?
                            <p>Para completar su check-out, deberá tomar un video corto del apartamento</p> :
                            <p>Here is an explanation video on how to use the SmartScanner</p>
                        }
                        btnText={lang === "es" ? "Siguiente" : "Next"}
                        onBtnClick={() => switchStep(STEP_EXP1)}
                        step={1}
                        totalSteps={4}
                    /> :
                step === STEP_EXP1 ?
                    <ScanStep
                        img={step1x1}
                        imgx2={step1x2}
                        imgx3={step1x3}
                        text={
                            lang === "es" ?
                            <p>Para completar su check-out, deberá tomar un video corto del apartamento</p> :
                            <p>In order to complete your contactless checkout you'll need to take a short video of the apartment.</p>
                        }
                        btnText={lang === "es" ? "Siguiente" : "Next"}
                        onBtnClick={() => switchStep(STEP_EXP2)}
                        step={1}
                        totalSteps={4}
                    /> :
                step === STEP_EXP2 ?
                    <ScanStep
                        img={step2x1}
                        imgx2={step2x2}
                        imgx3={step2x3}
                        text={
                            lang === "es" ? 
                            <p>Una vez haga clic “Empezar”, vas a recibir: <br/>1. Una foto para guiar, en la esquina de arriba de la pantalla <br/>2. Instrucciones de la pantalla de donde grabar con su celular</p> :
                            <p>Once you click "Let's Start", you will receive:<br/>1. A picture to capture, on the top right corner of your screen<br/>2. On screen instructions on where to move with your camera.</p>}
                        btnText={lang === "es" ? "Siguiente" : "Next"}
                        onBtnClick={() => switchStep(STEP_EXP3)}
                        step={2}
                        totalSteps={4}
                    /> :
                step === STEP_EXP3 ?
                    <ScanStep
                        img={step3x1}
                        imgx2={step3x2}
                        imgx3={step3x3}
                        text={lang === "es" ? <p>Esto no va a durar más que unos pocos minutos</p> : <p>This shouldn't take more than a few minutes.</p>}
                        btnText={lang === "es" ? "Empecemos" : "Let's Start"}
                        onSkip={null}
                        onBtnClick={() => switchStep(STEP_SCAN)}
                        step={3}
                        totalSteps={4}
                    /> :
                step === STEP_SCAN ?
                    (sockets && (scanData?.instructions ?
                        <InteractiveVideoCameraV2
                            sockets={sockets}
                            FPS={FPS}
                            cameraConstraints={getFinalConstraints()}
                            switchToLoading={switchToLoading}
                            finalizationCallback={finalizeScan}
                            showError={showError}
                            endUserInfo={{
                                    'pid': pid, 
                                    'uf': {'dir': scanData.uni_frames[0].split("/").pop().split("?")[0], 'len': scanData.uni_frames.length}, 
                                    'bs_scan_data': JSON.stringify(scanData.scan_metadata.scan_session),
                                    'inspection_type': inspection_type
                                }}
                            camType={CAM_TYPE_TENANT_SCAN}
                            tenantScanData={[scanData && scanData.instructions ? scanData.instructions : null, scanData && scanData.uni_frames ? scanData.uni_frames : null, scanData && scanData.uf_data ? scanData.uf_data : null]}
                            lang={lang}
                            framesCache={framesCache}
                            compressSize={shouldCompress ? 640 : undefined}
                            setRecordedBlob={setRecordedBlob}
                            para_be={props.para_be}
                            transmitSid={(sid) => { scanSess.current = sid; }}
                        /> : 
                        <VideoCamera 
                            sockets={sockets}
                            FPS={FPS}
                            cameraConstraints={getFinalConstraints()}
                            switchToLoading={switchToLoading}
                            finalizationCallback={finalizeScan}
                            showError={showError}
                            endUserInfo={{
                                    'pid': pid, 
                                    'inspection_type': inspection_type,
                                    'auto_approve': autoApply
                                }}
                            camType={CAM_TYPE_TENANT_SCAN_NO_BASE}
                            showTimeStamp={true}
                            framesCache={framesCache}
                            compressSize={shouldCompress ? 640 : undefined}
                            setRecordedBlob={setRecordedBlob}
                            para_be={props.para_be}
                            transmitSid={(sid) => { scanSess.current = sid; }}
                        />
                        )
                    ) :
                step === STEP_DONE ? 
                    <SimpleLoader 
                        loaderSuccess={true} 
                        msg={<><b>Well Done!</b><br/>You've cleared the scan</>}
                        successCallback={redirectURL && (() => { window.location.href = redirectURL; })}
                        {...(recordedBlob ?
                            {
                                extraElementEnd: <>
                                    <a className="simple-btn btn-secondary" target="_blank" style={{marginTop: '20px'}} download={`${scanSess.current}.${getFileExtFromBlob(recordedBlob)}`} href={URL.createObjectURL(recordedBlob)}>Download Scan</a>
                                    {/* <Btn type="secondary" text="Upload Scan" style={{marginTop: '10px', marginBottom: '10px'}} onclick={() => uploadRecording(scanSess.current)} />
                                    <div className="text-2">Upload Status: {recordingUploadRate}/100 | {uploadStatus}</div> */}
                                </>
                            } : {}
                        )}
                    /> :
                step === STEP_FEEDBACK_1 ?
                    <FeedbackBlock 
                        feedbackQuestion="How would you rate your scanning experience?"
                        includeClientLogo={true}
                        clientLogo={clientLogo && clientLogo.includes("https://") ? clientLogo : `${base_url}/${clientLogo}`}
                        onNext={(selectedRate) => {
                                switchStep(STEP_FEEDBACK_2);
                                fetch(props.para_be + '/scan/rate-your-scan', postReqOptBuilder({
                                    'scan_id': scanID, 
                                    'pid': pid, 
                                    'scan_rating': selectedRate, 
                                    'ip_address': endUserData[0]
                                }))
                                .then(response => response.json())
                                .then(response => {
                                    console.log(response);
                                    if(response.status === 200) {
                                        console.log("Rating Sent");
                                    } else {
                                        //TODO - set error
                                        console.log("Failed sending rating");
                                    }
                                })
                                .catch ( error => { //TODO - add error handling
                                    console.log("Exception was thrown while sending scan rating");
                                    console.log(error);
                                });
                            }}
                    /> :
                (step === STEP_FEEDBACK_2 && 
                    <FeedbackBlock 
                        feedbackQuestion="How would you rate your stay?"
                        thankForFeedback={true}
                        includeClientLogo={true}
                        clientLogo={clientLogo && clientLogo.includes("https://") ? clientLogo : `${base_url}/${clientLogo}`}
                        onNext={(selectedRate) => {
                            fetch(props.para_be + '/scan/rate-your-scan', postReqOptBuilder({
                                'scan_id': scanID, 
                                'pid': pid, 
                                'stay_rating': selectedRate, 
                                'ip_address': endUserData[0]
                            }))
                            .then(response => response.json())
                            .then(response => {
                                console.log(response);
                                if(response.status === 200) {
                                    console.log("Rating Sent");
                                } else {
                                    //TODO - set error
                                    console.log("Failed sending rating");
                                }
                            })
                            .catch ( error => { //TODO - add error handling
                                console.log("Exception was thrown while sending scan rating");
                                console.log(error);
                            });
                            }}
                    />
                )
            }
            {step !== STEP_DONE && step !== STEP_FEEDBACK_1 && step !== STEP_FEEDBACK_2 && finalizingScan ? 
                (
                    <SimpleLoader 
                        loadedPercentage={
                            (step === STEP_UPLOAD_FAILED ? 
                                null :
                                (step === STEP_LOADING_ISSUE_RESOLVED || step === STEP_RETRY_FILE_UPLOAD) ? 
                                    (loadedPercentage > recordingUploadRate ? 
                                        loadedPercentage : 
                                        recordingUploadRate
                                    ) : loadedPercentage
                            )
                        } 
                        msg={
                            step === STEP_LOADING_ISSUE ?
                                <>
                                    Something Went Wrong<br/>
                                    <div className="text-0">Do not leave the screen.</div>
                                    {fileUploadTimer &&
                                        <div className="text-5">Retry Time Left: {Math.max(Math.round(((fileUploadTimer - (new Date())) / 1000)), 0)} Seconds</div>
                                    }
                                    <br/>
                                    <div className="text-3">
                                        We noticed an issue with your network connection.<br/>
                                        Please hang tight while we try to resolve it.
                                    </div>
                                </> : 
                            (step === STEP_RETRY_FILE_UPLOAD ?
                                <>
                                    Loading<br/>
                                    <div className="text-0">Do not leave the screen</div>
                                    {fileUploadTimer &&
                                        <div className="text-5">Retry Time Left: {Math.max(Math.round(((fileUploadTimer - (new Date())) / 1000)), 0)} Seconds</div>
                                    }
                                    <br/>
                                    <div className="text-3">
                                        The network issue persists.<br/>
                                        Please hold on for a bit longer as we are trying to establish connection.
                                    </div>
                                </> :
                                (step === STEP_LOADING_ISSUE_RESOLVED ?
                                    <>
                                        Loading<br/>
                                        <div className="text-0">Do not leave the screen</div>
                                        {fileUploadTimer &&
                                            <div className="text-5">Retry Time Left: {Math.max(Math.round(((fileUploadTimer - (new Date())) / 1000)), 0)} Seconds</div>
                                        }
                                        <br/>
                                        <div className="text-3">Issue resolved! You should be on  your way in just a bit. Thank you for your patience!</div>
                                    </> :
                                    (step === STEP_UPLOAD_FAILED ?
                                        <>
                                            Upload Failed<br/>
                                            <div className="text-0">Network Connectivity Issue</div><br/>
                                            <div className="text-3">
                                                Your network connection is unavailable or unstable.<br/>
                                                We appreciate your patience as we tried to mediate the issue on your device.<br/>
                                                Please download the scan, by clicking on the Download Scan button below, and upload it once you are connected to a stable network.<br/>
                                                You may also retry to upload the scan by clicking on the Retry button below.<br/><br/>
                                                Thank you for your cooperation!
                                            </div>
                                        </> :
                                        <>
                                            Loading<br/>
                                            <div className="text-0">Do not leave the screen.</div>
                                            {fileUploadTimer &&
                                                <div className="text-5">Retry Time Left: {Math.max(Math.round(((fileUploadTimer - (new Date())) / 1000)), 0)} Seconds</div>
                                            }
                                            <br/>
                                            <div className="text-3">If your network connection is slow or unstable, you may experience longer upload times. We appreciate your patience!</div>
                                        </>
                                    )
                                )
                            )
                        }
                        onHelp={() => handleUploadVideo(`${scanID}_${pid}`)}
                        remainingData={remainingFrames}
                        {...(recordedBlob ?
                                {
                                    extraElementEnd: <>
                                        <a className="simple-btn btn-secondary" target="_blank" style={{marginTop: '20px', marginBottom: '10px'}} download={`${scanSess.current}.${getFileExtFromBlob(recordedBlob)}`} href={URL.createObjectURL(recordedBlob)}>Download Scan</a>
                                        {step === STEP_UPLOAD_FAILED &&
                                            <Btn 
                                                type="secondary" 
                                                text="Retry Upload" 
                                                style={{marginTop: '10px', marginBottom: '10px'}} 
                                                onclick={() => {
                                                    switchStep(STEP_RETRY_FILE_UPLOAD);
                                                    uploadRecording(scanSess.current);
                                                }} 
                                            />
                                        }
                                        {debugMode &&
                                            <>
                                                <Btn type="secondary" text="Upload Scan" style={{marginTop: '10px', marginBottom: '10px'}} onclick={() => uploadRecording(scanSess.current)} />
                                                <div className="text-2">Upload Status: {recordingUploadRate}/100 | {uploadStatus}</div>
                                            </>
                                        }
                                    </>
                                } : {}
                        )}
                    />
                ) : ""}
            {popupState ? <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup> : ""}
        </section>
    )
}
