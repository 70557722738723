import { createSlice } from '@reduxjs/toolkit';

const configSlice = createSlice({
    name: 'config',
    initialState: {
        isMobile: null,
        sidebarOpenMobile: false,
        sidebarOpen: true,
        lastPage: null,
    },
    reducers: {
        setIsMobile(state, action) {
            state.isMobile = action.payload;
        },
        setSidebarOpenMobile(state, action) {
            state.sidebarOpenMobile = action.payload;
        },
        setSidebarOpen(state, action) {
            state.sidebarOpen = action.payload;
        },
        setLastPage(state, action) {
            state.lastPage = action.payload;
        },
        clearIsMobile(state) {
            state.isMobile = null;
        },
    },
});

export const { setIsMobile, clearIsMobile, setSidebarOpenMobile, setSidebarOpen, setLastPage } = configSlice.actions;

export default configSlice.reducer;
