import { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
//style
import './App.css';
import './styles/output.css';
//pages
import Management from "./containers/management/management";
import Tenancies from './containers/tenancies/tenancies';
import ReviewPage from "./containers/review_page/review_page";
import Login from "./containers/login/login"
import Settings from './containers/settings/settings';
import LoadingPage from './containers/loading_page/loading_page';
import SignUp from "./containers/sign_up/sign_up.js"
import CreateCompany from './containers/create_company/create_company';
import DemoScan from "./containers/scan_page/demo_scan";
import BaselineScan from "./containers/scan_page/baseline_scan";
import TenantScan from "./containers/scan_page/tenant_scan";
import HomePage from './containers/outFacingWebsite/pages/home/home.js';
import ScanManagement from './containers/scan_management/scan_management';
import VideoUploader from './containers/scan_page/upload_baseline';
import AdminScanDashboard from './containers/scans_dashboard/scans_dashboard';
import AdminScanFramesViewer from './containers/scans_dashboard/viewScan';
import ClientScanDashboard from './containers/scans_dashboard/client_scans_dashboard';
import InventoryReview from './containers/scan_management/baseline_inventory_review';
import ClientLanding from './containers/move_out_screen/move_out';
import ScanSubmissions from './containers/scan_submissions/scan_submissions.js';
import ManagementDev from './containers/ManagementDev'
import Admin_Management from './containers/admin_paraspot/management.js';
//components
import StickyHeader from './components/StickyHeader';
import SideBarNavigation from './components/SideBarNavigation';
//store
import { setIsMobile, setLastPage } from './store/slices/configSlice';
//hooks
import useAuthenticate from './hooks/useAuthenticate.js';
//utils
import { isMobile as isMobileDevice, urlSearchToObject, urlListContains } from './utils/main_utils';


function App() {
	const maintenanceState = false;
	const cred = useSelector((state) => state.auth.cred);
	
	// const { isMobile } = useSelector(state => state.config);
	const { sidebarOpen } = useSelector(state => state.config);
    const dispatch = useDispatch();
	const { token, authentication, settingUser, settingClient } = useAuthenticate();
	
	const history = useHistory();
	const location = useLocation();
	const base_url = window.location.protocol + "//" + window.location.hostname + (window.location.port ? `:${window.location.port}` : "");
	const base_url_no_port = window.location.protocol + "//" + window.location.hostname;
	const canonicalLink = base_url_no_port + window.location.pathname;
	const para_be = base_url_no_port + (window.location.port ? ':5000' : "") + "/api";
	const disableNavBarEndPoints = [
    	"^/login[/]{0,1}$", "^/signup[/]{0,1}$", "^/about[/]{0,1}$", "^/[/]{0,1}$", "^$", "^/demo-scan[/]{0,1}$", "/checkout_landing_page$",
		"^/demo-scan/t[0-9]+[/]{0,1}$", "^/[^/]+/baseline-scan[/]{0,1}$", "^/checkout/[^/]+/[^/]+[/]{0,1}$", "^/create_company[/]{0,1}$",
		"^/inspection/[^/]+/[^/]+/[^/]+[/]{0,1}$"
    ];
	const endpoints = ["/login", "/signup", "/about", "/management", "/last-report", "/settings"];
	const extendedEndpoints = [
		{v: "/report/", f: (t, s) => t.startsWith(s)},
		{v: "/checkout/", f: (t, s) => t.startsWith(s)},
		{v: "/inspection/", f: (t, s) => t.startsWith(s)},
		{v: "/paraspot/admin/", f: (t, s) => t.startsWith(s)},
		{v: "/baseline-scan", f: (t, s) => t.endsWith(s)},
		{v: "/upload-baseline-scan", f: (t, s) => t.endsWith(s)},
	]

	const isAllowedMaintenancePass = (__cred) => {
		console.log("maintenanceState:", maintenanceState);
		return maintenanceState ? (__cred && __cred['cid'] === "123456789") : true;
	}

	const handleResize = () => {
		if (window.innerWidth < 1000) {
			dispatch(setIsMobile(true));
		} else {
			dispatch(setIsMobile(false));
		}
	}
  
	useEffect(()=>{
		authentication();
        dispatch(setIsMobile(isMobileDevice() || window.innerWidth < 1000));
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const historyListener = history.listen((newLocation, action) => {
			dispatch(setLastPage(location.pathname));
		});
	
		return () => {
			historyListener(); // Clean up listener
		};
	}, [history, location]);

	useEffect(() => {
		if (cred && cred['cid']) {
			if (window.pendo) {
				window.pendo.initialize({
					visitor: {
						id: cred['uid'],
						email: settingUser?.email,
						firstName: settingUser?.firstName,
						lastName: settingUser?.lastName,
					},
					account: {
						id: cred['cid'],
						accountName: settingClient?.name,
					}
				});
			} else {
				console.log("Could not find Pendo in the web application");
			}
		}
	}, [cred]);



  	return (
		<div className="App min-h-ful">
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover"/>
				<link rel="canonical" href={canonicalLink}/>
				<meta property="og:url" content={base_url}/>
			</Helmet>

			{!urlListContains(disableNavBarEndPoints, window.location.pathname) && <SideBarNavigation />}

			<div className={`flex flex-col ${urlListContains(disableNavBarEndPoints, window.location.pathname) ? '' : sidebarOpen ? 'xl:pl-64' : 'xl:pl-8'}`}>
			{/*show navbar only for authenticated users and NOT about page */}
			{!urlListContains(disableNavBarEndPoints, window.location.pathname) ? <StickyHeader /> : ""}
				
				<Switch>
					<Route exact path="/create_company">
						{urlSearchToObject(window.location.search)["admin_token"] === "ParaOnTheSpot2020!" ? 
							<CreateCompany para_be={para_be} uid_cid={cred}/> :
							<Redirect to={cred ? "/management" : "/login"}/>
						}
					</Route>
					<Route exact path="/login">
						{cred ? <Redirect to ="/management"/> : <Login para_be={para_be}/> }
					</Route>
					<Route exact path="/signup">
						{cred ? <Redirect to ="/management"/> : <SignUp para_be={para_be}/> }
					</Route>
					<Route exact path="/about" render={() => <HomePage para_be={para_be}/>}/>
					<Route exact path="/" render={() => <HomePage para_be={para_be}/>}/>
					<Route exact path="/:cbe_name/checkout_landing_page" render={(props) => <ClientLanding para_be={para_be} base_url={base_url} {...props}/>}/>
				
					<Route 
						exact path="/checkout/:cbe_name/:scan_id" 
						render={(props) => <TenantScan para_be={para_be} base_url={base_url} inspection_type={'checkout'} token={token.current} {...props}/>} />
					<Route 
						exact path="/checkin/:cbe_name/:scan_id"
						render={(props) => <TenantScan para_be={para_be} base_url={base_url} inspection_type={'checkin'} token={token.current} {...props}/>} />
					<Route 
						exact path="/inspection/:inspection_type/:cbe_name/:scan_id"
						render={(props) => <TenantScan para_be={para_be} base_url={base_url} token={token.current} {...props}/>} />
					
					{cred &&
						<>
							{encodeURIComponent((new URLSearchParams(window.location.search)).get('token')) === "paraspotAdmin22!" && 
								<>
									<Route exact path="/paraspot/admin/sm/:rid" render={(props) => <ScanManagement para_be={para_be} {...props}/>} /> 
									<Route exact path="/paraspot/admin/dashboard" render={(props) => <AdminScanDashboard para_be={para_be} {...props}/>} /> 
									<Route exact path="/paraspot/admin/scan_viewer/:sid" render={(props) => <AdminScanFramesViewer para_be={para_be} {...props}/>} /> 
								</>
							}
							<>
								<Route exact path="/demo-scan" render={() => <DemoScan para_be={para_be}/>} /> 
								<Route exact path="/paraspot/admin/clients">
									{urlSearchToObject(window.location.search)["admin_token"] === "ParaOnTheSpot2020!" ?
										<Admin_Management token="ParaOnTheSpot2020!" para_be={para_be} /> :
										<Redirect to="/management"/>
									}
								</Route>
								<Route exact path="/management" render={() => <ManagementDev  Dev uid_cid={cred} para_be={para_be}/>} />
								<Route exact path="/units" render={() => <Management uid_cid={cred} para_be={para_be}/>} />
								<Route exact path="/tenancies" render={() => <Tenancies uid_cid={cred} para_be={para_be}/>} />
								<Route exact path="/scan_submissions" render={() => <ScanSubmissions uid_cid={cred} para_be={para_be}/>} />
								<Route exact path="/scans_dashboard" render={() => <ClientScanDashboard uid_cid={cred} para_be={para_be}/>} />
								<Route exact path="/report/:rid" render={(props) => <ReviewPage para_be={para_be}  uid_cid={cred} {...props}/>} />
								<Route path="/settings" render={(props) => <Settings para_be={para_be} uid_cid={cred} {...props} />} />

								<Route 
									exact path="/:pid/unit-inventory" 
									render={(props) => <InventoryReview uid_cid={cred} token={token.current} para_be={para_be} base_url={base_url} {...props}/>} />
								<Route 
									exact path="/scan/:src_id/inventory" 
									render={(props) => <InventoryReview uid_cid={cred} token={token.current} para_be={para_be} base_url={base_url} {...props}/>} />
								<Route 
									exact path="/:pid/baseline-scan"
									render={(props) => <BaselineScan uid_cid={cred} token={token.current} para_be={para_be} base_url={base_url} {...props}/>} />
								<Route 
									exact path="/:pid/upload-baseline-scan"
									render={(props) => <VideoUploader uid_cid={cred} token={token.current} para_be={para_be} base_url={base_url} {...props}/>} />
							</> 
							
						</> 
					}
					{
						endpoints.includes(window.location.pathname) || extendedEndpoints.map((i) => i.f(window.location.pathname, i.v)) ? <LoadingPage/> :
							<>
								<Route render={() => <Login para_be={para_be}/>}/>
							</>
					}
		
				
				</Switch>
			
			</div>
		</div>
  	);
}



export default App;