import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openVideoModal, openUploaderModal } from '../../../../../../../../../../store/slices/dashboardSlice';
import TableOptionsMenu from '../../../TableOptionsMenu';
import DocsIcon from '../../../../../../icons/docsIcon';
import PlayIcon from '../../../../../../icons/playIcon';
import PlainIcon from '../../../../../../icons/plainIcon';
import EyeIcon from '../../../../../../icons/eyeIcon';
import UploadIcon from '../../../../../../icons/uploadIcon';
import BuildingIcon from '../../../../../../icons/buildingIcon';
import { SimpleDropdownContainer } from '../../../../../../../../../../components/dropdowns/simple_dropdown/simple_dropdown';
import { postReqOptBuilder } from '../../../../../../../../../../utils/main_utils';
import { para_be } from '../../../../../../../../../../config';
import { SeverityLevels } from '../../../../../../../../../../constants';



const OptionsMenu = React.memo(({ pid, subject }) => {
  const menuItems = [
      {
        label: 'View baseline',
        icon: EyeIcon,
        onClick: (dispatch) => dispatch(openVideoModal({ pid, subject }))
      },
      {
        label: 'Upload move-out scan',
        icon: UploadIcon,
        onClick: (dispatch) => dispatch(openUploaderModal({ pid })),
      },
      {
        label: 'View unit',
        icon: BuildingIcon,
        onClick: () => {}, 
        disabled: true,
      },
    ];

    return (
      <TableOptionsMenu items={menuItems} />
    );
});


const ActionsPanel = ({pid,subject,latest_report_id,changeSetNotifState,videoLink,scan_id}) => {
  const dispatch = useDispatch();
  const [showShareLinkDD, setShowShareLinkDD] = React.useState(false);
  const [shareLink, setShareLink] = React.useState(scan_id);
  const [loadingShareLink, setLoadingShareLink] = React.useState(false);

  const generateURL = (e) => {
    e.stopPropagation();
    setLoadingShareLink(true);
    
    fetch(para_be + '/units/generate_scan_link', postReqOptBuilder({"pid": pid}))
    .then(response => response.json())
    .then(response => {
      if(response.status === 200) {
        setShareLink(response.result);
        setLoadingShareLink(false);
      } else {
        changeSetNotifState({text: response.msg, type: SeverityLevels.ERROR});
        setLoadingShareLink(false);
      }
    })
    .catch(error => {
      changeSetNotifState({text: "Something went wrong", type: SeverityLevels.ERROR});
      console.log(error);
    });
    return false;
  };

  const handleCopy = (e, inspection_type=null) => {
    e.stopPropagation();
    
    // const textToCopy = `${window.location.origin}/checkout/${pid}`;
    const textToCopy = window.location.origin + "/inspection/" + (inspection_type === null ? 'checkout' : inspection_type) + "/" + shareLink;

    if (navigator.clipboard && navigator.clipboard.writeText) {
        // Modern method (Clipboard API)
        navigator.clipboard.writeText(textToCopy).then(() => {
            changeSetNotifState("Link copied successfully!");
        }).catch((error) => {
            changeSetNotifState({text: "Failed to copy.", type: SeverityLevels.ERROR});
            console.error("Clipboard error:", error);
        });
    } else {
        // Fallback for older browsers (execCommand method)
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        textArea.style.position = "fixed";  // Avoid scrolling to bottom of the page
        textArea.style.opacity = "0";       // Make it invisible
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        
        try {
            const successful = document.execCommand('copy');
            if (successful) {
                changeSetNotifState("Link copied successfully!");
            } else {
                changeSetNotifState({text: "Failed to copy.", type: SeverityLevels.ERROR});
            }
        } catch (err) {
            console.error("Fallback: Unable to copy", err);
            changeSetNotifState({text: "Failed to copy.", type: SeverityLevels.ERROR});
        }
        
        document.body.removeChild(textArea);
    }
    
    setShowShareLinkDD(false);
    setTimeout(() => changeSetNotifState(null), 3000);
 };

  return (
    <>
      {latest_report_id ? (
        <>
          <span
            className={`cursor-pointer`}
            onClick={(e) => {
              e.stopPropagation();
              console.log("Calling openVideoModal", videoLink ? { subject, videoLink } : { pid, subject });
              dispatch(openVideoModal({ subject, videoLink }));
            }}
          >
            <PlayIcon />
          </span>
          <Link to={`/report/${latest_report_id}`} className="cursor-pointer">
            <DocsIcon />
          </Link>
        </>
      ) : (
        <>
          <span className='cursor-default'>
            <PlayIcon disabled />
          </span>
          <span className='cursor-default'>
            <DocsIcon disabled />
          </span>
        </>
      )}
      <SimpleDropdownContainer
        extraClasses="relative flexRow"
        extraClassesIn="right-0 top-7"
        showDropdown={showShareLinkDD}
        borderedItems={true}
        items={
          loadingShareLink ?
          {
            'loading': {
              'present': 'Loading...',
              'onclick': (k, e) => {
                e.preventDefault();
                e.stopPropagation();
              }
            }
          } :
          shareLink ?
          {
            'check_in': {
              'present': 'Check-In',
              'onclick': (k, e) => {
                e.preventDefault();  
                handleCopy(e, 'checkin');
              }
            },
            'check_out': {
              'present': 'Check-Out',
              'onclick': (k, e) => {
                e.preventDefault();  
                handleCopy(e, 'checkout');
              }
            }
          } :
          {
            'gen_link': {
              'present': 'Generate Link',
              'onclick': (k, e) => {
                e.preventDefault();  
                generateURL(e);
              }
            }
          }
        }
      >
        {/* <span className="cursor-pointer" onClick={(e) => handleCopy(e)}> */}
        <span className="cursor-pointer" onClick={(e) => { e.stopPropagation(); setShowShareLinkDD(!showShareLinkDD); }}>
          <PlainIcon />
        </span>
      </SimpleDropdownContainer>
      <OptionsMenu
        pid={pid}
        subject={subject}
      />
    </>
  )
}

export default ActionsPanel
