import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//slices
import { removeBillingMethod, fetchBillingDetails, clearError, setDefaultPaymentMethod } from '../../../../../../store/slices/billingSlice';
//components
import SnackBar from '../../../../../../components/SnackBar';
import ConfirmationDialog from '../../../../../../components/ConfirmationDialog';
import CircularLoader from '../../../../../../components/loaders/CircularLoader';
//assets
import { CreditCardIcon } from '@heroicons/react/20/solid';
//constants
import { SeverityLevels } from '../../../../../../constants';
//utils
import { classNames } from '../../../../../../utils/main_utils';

const statusStyles = {
    default: 'bg-blue-100 text-blue-800',
};

const ShowBillingDetails = () => {
    const dispatch = useDispatch();
    const { billingDetails, loading, error } = useSelector(state => state.billing);

    const [open, setOpen] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [notification, setNotification] = useState({
        open: false,
        severity: SeverityLevels.SUCCESS,
        message: '',
    });

    useEffect(() => {
        dispatch(fetchBillingDetails());
    }, [dispatch]);

    const handleClickOpen = paymentMethod => {
        if (billingDetails.default_payment_method === paymentMethod.id && billingDetails.payment_methods.length > 1) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Cannot remove the default payment method.'});
            return;
        }

        setSelectedPaymentMethod(paymentMethod);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedPaymentMethod(null);
    };

    const handleRemove = async () => {
        if (!selectedPaymentMethod) return;

        try {
            await dispatch(removeBillingMethod(selectedPaymentMethod.id)).unwrap();
            setNotification({open: true, severity: SeverityLevels.SUCCESS, message: 'Payment method removed successfully!'});
            handleClose();
        } catch (err) {
            handleClose();
        }
    };

    const handleSetDefault = async (paymentMethodId) => {
        try {
            await dispatch(setDefaultPaymentMethod(paymentMethodId)).unwrap();
            setNotification({open: true, severity: SeverityLevels.SUCCESS, message: 'Default payment method updated successfully!'});
            await dispatch(fetchBillingDetails());
        } catch (err) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: 'Failed to update default payment method.'});
        }
    };

    useEffect(() => {
        if (error) {
            setNotification({open: true, severity: SeverityLevels.ERROR, message: error || 'Error removing payment method'});
            dispatch(clearError());
        }
    }, [error]);

    const handleNotificationClose = () => {
        setNotification({ ...notification, open: false });
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <CircularLoader size={36} color="secondary" />
            </div>
        );
    }

    if (!billingDetails || billingDetails.payment_methods.length === 0) {
        return <h2 className="mx-auto max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">No billing details found</h2>;
    }

    const defaultPaymentMethodId = billingDetails.default_payment_method;

    return (
        <>
            <div className="min-h-full">
                <div className="flex flex-1 flex-col">
                    <main className="flex-1 pb-8">
                        <h2 className="mx-auto max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">Billing Details</h2>

                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl">
                                <div className="mt-2 flex flex-col">
                                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                                                        Payment Method
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                                                        Expiration
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {billingDetails.payment_methods.map(method => (
                                                    <tr key={method.id} className="bg-white">
                                                        <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                            <div className="flex items-center">
                                                                <CreditCardIcon 
                                                                    aria-hidden="true"
                                                                    className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                                                                <span className="ml-2 truncate text-gray-500 group-hover:text-gray-900">
                                                                    {method.brand.toUpperCase()} **** {method.last4}
                                                                </span>
                                                                {method.id === defaultPaymentMethodId && (
                                                                    <span className={classNames(statusStyles.default, 'inline-flex items-center rounded-full px-2.5 py-0.5 ml-2 text-xs font-medium capitalize')}>
                                                                        Default
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500">
                                                            {method.exp_month}/{method.exp_year}
                                                        </td>
                                                        <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                            {method.id !== defaultPaymentMethodId &&
                                                                <button className="text-blue-600 hover:text-blue-900 mr-4" onClick={() => handleSetDefault(method.id)}>
                                                                    Set as Default
                                                                </button>
                                                            }
                                                            <button className="text-red-600 hover:text-red-900" onClick={() => handleClickOpen(method)}>
                                                                Remove
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Activity list (smallest breakpoint only) */}
                        <div className="shadow sm:hidden">
                            <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                {billingDetails.payment_methods.map((method) => (
                                    <li key={method.id}>
                                        <div className="block bg-white px-4 py-4 hover:bg-gray-50">
                                            <span className="flex items-center space-x-4">
                                                <span className="flex flex-1 space-x-2 truncate">
                                                    <CreditCardIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                                    <span className="flex flex-col truncate text-sm text-gray-500">
                                                        <span className="truncate">
                                                            {method.brand.toUpperCase()} **** {method.last4}
                                                        </span>
                                                        <span>
                                                            <span className="font-medium text-gray-900">
                                                                Expires: {method.exp_month}/{method.exp_year}
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                                {method.id === defaultPaymentMethodId &&
                                                    <span className={classNames(statusStyles.default, 'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize')}>Default</span>
                                                }
                                            </span>
                                            <div className="flex justify-between mt-2">
                                                <button
                                                    className="text-blue-600 hover:text-blue-900 text-sm font-medium"
                                                    onClick={() => handleSetDefault(method.id)}
                                                    disabled={method.id === defaultPaymentMethodId}
                                                >
                                                    Set as Default
                                                </button>
                                                <button className="text-red-600 hover:text-red-900 text-sm font-medium" onClick={() => handleClickOpen(method)}>
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </main>
                </div>
            </div>

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={open}
                handleClose={() => {
                    handleClose();
                    dispatch(clearError());
                }}
                handleRemove={handleRemove}
                text={'Are you sure you want to remove this payment method? This action cannot be undone.'}
                header={'Remove payment method'} />

            {/* Notification */}
            {notification.open &&
                <SnackBar 
                    open={notification.open}
                    handleClose={handleNotificationClose}
                    severity={notification.severity}
                    message={notification.message}
                    duration={6000} />
            }
        </>
    );
};

export default ShowBillingDetails;
