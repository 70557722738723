import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useFetchExtendedInfo from '../../hooks/useFetchExtendedInfo';
import ActionLog from './components/ActionLog';
import TableBodyRowTenantDetails from '../TableBodyRowTenantDetails';
import { formatKey } from '../../utils';
import CircularLoader from '../../../../../../../../components/loaders/CircularLoader';
import ArrowRightIcon from '../../../../icons/arrowRightIcon';


// Mobile version of ExpandedDetails
const ExpandedDetailsMobile = ({ extendedInfoArr, actionLogs, isExtendedInfoLoading }) => (
  <div className="mt-3">
    <div className="grid grid-cols-1 gap-2">
      <div>
        {!isExtendedInfoLoading &&
          extendedInfoArr.labels.map((label) => {
            return (
              <div className="mb-2">
                <span className={"inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset " + (label === 'Connected to PMS' ? "text-green-600 ring-green-500" : "text-blue-600 ring-blue-500")}>
                  {label}
                </span>
              </div>
            )
          })
        }
        {/* <div className="mb-2">
          <span className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500">
            Connected to PMS
          </span>
        </div>
        <div className="mb-2">
          <span className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500">
            Occupied
          </span>
        </div> */}
        {/* <h4>Reference</h4>
        <p className="text-gray-500 mb-3">21312321</p> */}
        {/* <a
          href="/"
          className="whitespace-nowrap inline-flex items-center gap-x-1 font-medium text-indigo-600 hover:text-indigo-800"
        >
          View unit
          <ArrowRightIcon />
        </a> */}
      </div>

      <div>
        {!isExtendedInfoLoading ? (
          Object.keys(extendedInfoArr).map((key) => (
            <TableBodyRowTenantDetails key={key} header={formatKey(key)} value={extendedInfoArr[key] || 'N/A'} />
          ))
        ) : (
          <CircularLoader size={36} color="secondary" />
        )}
      </div>

      <div>
        <ActionLog actions={actionLogs} />
      </div>
    </div>
  </div>
);

// Desktop version of ExpandedDetails
const ExpandedDetailsDesktop = ({ extendedInfoArr, actionLogs, isExtendedInfoLoading }) => (
  <tr className="bg-gray-100">
    <td></td>
    <td className="py-3 align-top">
      {!isExtendedInfoLoading &&
        (extendedInfoArr?.labels || []).map((label) => {
          return (
            <div className="mb-2">
              <span className={"inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset " + (label === 'Connected to PMS' ? "text-green-600 ring-green-500" : "text-blue-600 ring-blue-500")}>
                {label}
              </span>
            </div>
          )
        })
      }
      {/* <div className="mb-2">
        <span className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-green-600 ring-1 ring-inset ring-green-500">
          Connected to PMS
        </span>
      </div>
      <div className="mb-2">
        <span className="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-blue-500">
          Occupied
        </span>
      </div> */}
      {/* <h4>Reference</h4>
      <p className="text-gray-500 mb-3">21312321</p> */}
      {/* <a
        href="/"
        className="whitespace-nowrap inline-flex items-center gap-x-1 font-medium text-indigo-600 hover:text-indigo-800"
      >
        View unit
        <ArrowRightIcon />
      </a> */}
    </td>
    <td className="py-3 align-top" colSpan="2">
      {!isExtendedInfoLoading ? (
        Object.keys(extendedInfoArr).map((key) => (
          key === "labels" ? "" :
          <TableBodyRowTenantDetails key={key} header={formatKey(key)} value={extendedInfoArr[key] || 'N/A'} />
        ))
      ) : (
        <CircularLoader size={36} color="secondary" />
      )}
    </td>
    <td></td>
    <td className="py-3 align-top">
      <ActionLog actions={actionLogs} />
    </td>
  </tr>
);

// Main ExpandedDetails component that chooses which version to render
const ExpandedDetails = ({ pid, isOpen }) => {
  const { extendedInfo, isExtendedInfoLoading } = useFetchExtendedInfo(pid, isOpen);
  const [actionLogs, setActionLogs] = useState([]);
  const { isMobile } = useSelector((state) => state.config);

  console.log("ExpandedDetails: ", extendedInfo);
  console.log("isExtendedInfoLoading: ", isExtendedInfoLoading);
  // Conditionally render mobile or desktop based on isMobile flag
  return (
    <>
      {isMobile ? (
        <ExpandedDetailsMobile extendedInfoArr={extendedInfo} isExtendedInfoLoading={isExtendedInfoLoading}  actionLogs={actionLogs} />
      ) : (
        <ExpandedDetailsDesktop extendedInfoArr={extendedInfo} isExtendedInfoLoading={isExtendedInfoLoading} actionLogs={actionLogs} />
      )}
    </>
  );
};

export default ExpandedDetails;