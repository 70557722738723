import { configureStore } from '@reduxjs/toolkit';
import customerReducer from './slices/customerSlice';
import subscriptionReducer from './slices/subscriptionsSlice';
import billingReducer from './slices/billingSlice';
import authReducer from './slices/authSlice';
import configReducer from './slices/configSlice';
import notificationsReducer from './slices/notificationSlice';
import baselineScanReducer from './slices/baselineScanSlice';
import dashboardReducer from './slices/dashboardSlice'

export const store = configureStore({
    reducer: {
        dashboard:dashboardReducer,
        baselineScan: baselineScanReducer,
        notifications: notificationsReducer,
        customer: customerReducer,
        subscription: subscriptionReducer,
        billing: billingReducer,
        auth: authReducer,
        config:configReducer
    },
});

export default store;
