import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//components
import CircularLoader from '../../../../../../components/loaders/CircularLoader';
import ConfirmationDialog from '../../../../../../components/ConfirmationDialog';
//slices
import { fetchCustomerData, removeCustomer } from '../../../../../../store/slices/customerSlice';
import { fetchSubscriptionData } from '../../../../../../store/slices/subscriptionsSlice';
import { clearPaymentState } from '../../../../../../store/slices/billingSlice';
//assets
import { EnvelopeIcon } from '@heroicons/react/20/solid';

const CustomerInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // Get the necessary state from Redux
    const { data, loading, error } = useSelector(state => state.customer);
    const { loading: removing } = useSelector(state => state.subscription); // Use a different loading state if needed
    const [open, setOpen] = useState(false);

    const handleRemoveCustomer = async () => {
        dispatch(removeCustomer()).then(() => {
            dispatch(fetchCustomerData());
            dispatch(fetchSubscriptionData());
            dispatch(clearPaymentState());
            history.push('/settings/payments/createSubscription');
        });
    };

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    if (loading || removing) {
        return (
            <div className="flex justify-center items-center h-full">
                <CircularLoader size={36} color="secondary" />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data) {
        return <div>No customer data available</div>;
    }

    return (
        <div className="flex flex-1 flex-col p-8 bg-white rounded-lg shadow-md max-w-md mx-auto">
            <h2 className="mt-6 text-base font-medium text-gray-900">{data?.customer_data?.name}</h2>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Email</dt>
                <dd className="text-sm text-gray-500">{data?.customer_data?.email}</dd>
                <dt className="sr-only">Balance</dt>
                <dd className="text-sm text-gray-500 mt-2">
                    Balance: {data?.customer_data.balance} {data?.customer_data.currency ? data?.customer_data?.currency.toUpperCase() : ''}
                </dd>
                <dt className="sr-only">Created</dt>
                <dd className="text-sm text-gray-500 mt-2">Created: {data?.customer_data?.currency ? new Date(data?.customer_data?.created * 1000).toLocaleDateString() : ''}</dd>
            </dl>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                        <a
                            href={`mailto:${data?.customer_data?.email}`}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                        >
                            <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                            Email
                        </a>
                    </div>
                    <div className="flex w-0 flex-1">
                        <button
                            onClick={handleOpenDialog}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-red-600 hover:bg-red-50"
                        >
                            Remove
                        </button>
                    </div>
                </div>
            </div>

            <ConfirmationDialog
                open={open}
                handleClose={handleCloseDialog}
                handleRemove={handleRemoveCustomer}
                text={'Are you sure you want to remove this customer? This action cannot be undone.'}
                header={'Remove Customer'}
            />
        </div>
    );
};

export default CustomerInfo;
