import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openVideoModal, 
        openApproveModal, 
        openRejectModal } from '../../../../../../../../../../store/slices/dashboardSlice';
import PlayIcon from '../../../../../../icons/playIcon';
import DocsIcon from '../../../../../../icons/docsIcon';
import CheckIcon from '../../../../../../icons/checkIcon';
import CancelIcon from '../../../../../../icons/cancelIcon';
import { colorKeysConfig } from '../../../../../../config/table.config';


const ActionsPanel = ({pid,subject,report_id,videoLink,submission_status}) => {
  const dispatch = useDispatch();
  return (
    <>
      <span className={`cursor-pointer`} onClick={(e) => {
            e.stopPropagation();
            dispatch(openVideoModal({ videoLink, subject }));
          }}>
            <PlayIcon />
      </span>
      {submission_status==="1" ?
        <span className={"font-medium " + colorKeysConfig.done}>Accepted</span> :
        submission_status==="2" ?
        <span className={"font-medium " + colorKeysConfig.missing}>Rejected</span> :
        <>
          <span className="cursor-pointer" onClick={(e) => {
                e.stopPropagation();
                dispatch(openApproveModal({ rid: report_id }));
              }}>
                <CheckIcon />
          </span>
          <span className="cursor-pointer" onClick={(e) => {
                e.stopPropagation();
                dispatch(openRejectModal({ rid: pid }));
          }}>
          <CancelIcon />
          </span>
        </>
      }
      {report_id && submission_status==="1" ? (
          <Link to={`/report/${report_id}`} className="cursor-pointer">
            <DocsIcon />
          </Link>
        ) : (
          <span className='cursor-default'>
            <DocsIcon disabled />
          </span>
        )
      }
    </>
  )
}

export default ActionsPanel
