/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
//components
import FeedbackTooltip from '../tooltips/feedback/feedback_tooltip';
import { postReqOptBuilder } from "../../utils/main_utils"
//assets
import copyIcon from "../../assets/icons/copy.svg";
import refreshIcon from "../../assets/icons/refresh-icon.png";
import { SimpleDropdownContainer } from '../dropdowns/simple_dropdown/simple_dropdown';

export default function UrlGeneratorCell(props) {
    const [url, setURL] = useState(props.scan_link);
    const [showTooltip, setShowTooltip] = useState(false);

    const generateURL = (propID) => {
        fetch(props.para_be + '/units/generate_scan_link', postReqOptBuilder({"cid": props.cid, "pid": propID}))
        .then(response => response.json())
        .then(response => {
            if(response.status === 200) {
                setURL(response.result);
                props.updateURL({"pid": propID, "url": response.result});
            } else {
                if (props.updateNotifState) props.updateNotifState({text: response.msg, type: 'error'})
            }
        })
        .catch(error => {
            if (props.updateNotifState) props.updateNotifState({text: "Something went wrong", type: 'error'})
            console.log(error);
        });
        return false;
    };

    const handleCopy = (inspection_type=null) => {
        if (inspection_type === null || inspection_type === 'checkout') {
            navigator.clipboard.writeText(window.location.origin + "/checkout/" + url);
        } else {
            navigator.clipboard.writeText(window.location.origin + "/inspection/" + (inspection_type === null ? 'checkout' : inspection_type) + "/" + url);
        }
        setShowTooltip(true);
        setTimeout(() => {
            setShowTooltip(false);
        }, 3000);
    };

    return (
        <div className={"generate-link " + (url === null ? "" : "link-created")}> 
            {url === null ?
                <a className='text-1-2' href="javascript:void(0);" onClick={() => generateURL(props.pid)}>Generate Link</a> :
                <div>
                    {/* <a className='text-ellipsis' href={props.scan_link ? "#" : ("/checkout/" + url)} onClick={() => {handleCopy(); return false;}}>{url}</a>
                    <img className="copy-icon" src={copyIcon} alt="copy-text" onClick={() => handleCopy()}/> */}
                    <SimpleDropdownContainer
                        extraClasses="copy-options flexRow"
                        showDropdown={props.showDropdown}
                        borderedItems={true}
                        items={{
                            'check_in': {
                                'present': 'Check-In',
                                'onclick': (k, e) => {
                                    e.preventDefault();  
                                    handleCopy('checkin');
                                }
                            },
                            'check_out': {
                                'present': 'Check-Out',
                                'onclick': (k, e) => {
                                    e.preventDefault();  
                                    handleCopy('checkout');
                                }
                            },
                            ...(props?.inspectionTypes ? props.inspectionTypes : {})
                        }}>
                        <div className="simple-btn flexRow" onClick={(e) => props.handleDropDown(e, `${props.pid}#copy-opt`)}>
                            <div className="text-3">Copy Link</div>
                            <img className="copy-icon" src={copyIcon} alt="copy-text" />
                        </div>
                    </SimpleDropdownContainer>
                    <img className="copy-icon" src={refreshIcon} alt="refresh" onClick={() => generateURL(props.pid)}/>
                    {showTooltip ? <FeedbackTooltip text = "Copied!"/> : ""}
                </div>
            }
        </div>
    )
}