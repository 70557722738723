import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useOpenController } from '../../../../hooks/useOpenController';
import ExpandedDetails from '../ExpandedDetails';
import ActionsPanel from './components/ActionsPanel';
import SnackBar from '../../../../../../../../components/SnackBar';
import AlertIcon from '../../../../icons/alertIcon';
import { dateTimeFormater } from '../../../../../../../../utils/date_utils';
import { SeverityLevels, CategoryFilter } from '../../../../../../../../constants';
import { colorKeysConfig } from '../../../../config/table.config';
import { moveOutHeaderConfig, moveInHeaderConfig } from '../../../../config/table.config';
import CustomTooltip from '../../../../../../../../components/CustomTooltip/CustomTooltip';


// Sub-component for mobile view
const MobileView = (props) => {
    const { ext_ref, subject, tenant_name, check_out, building_baseline,check_in, scan_status, scan_datetime, ts, scan_id, latest_scan_date,
           baseline_date, latest_report_id, pid, isOpen, toggle, changeSetNotifState } = props;
    const { categoryFilter } = useSelector((state) => state.dashboard);
    const moveOut = CategoryFilter.MOVE_IN === categoryFilter ? check_in : check_out;
    const headerConfig = CategoryFilter.MOVE_IN === categoryFilter ?  moveInHeaderConfig : moveOutHeaderConfig

    const headerDataMapping = {
        '1': ext_ref,
        '2': subject,
        '3': tenant_name,
        '4': moveOut ? dateTimeFormater(moveOut, 'yyyy/mm/dd') : '',
    };

    
    
    return (
        <div className="border border-gray-200 rounded-lg p-4 mb-4 bg-white shadow-sm" onClick={toggle} key={pid}>
            <div className="flex flex-col mb-3">
                <div className="flex-1">
                    {headerConfig.map((config) => {
                        if (config.value === 'Actions') {
                            return <></>
                        }
                        return(
                                <div className="flex overflow-hidden" key={config.key}>
                                    <span className="text-sm font-light text-gray-700">{`${config.value}:`}</span>
                                    {config.value !== 'Scan Status'  ?
                                        <span className="text-sm font-normal text-gray-900 ml-1 break-words whitespace-normal">
                                            {headerDataMapping[config.key] || ''}
                                        </span> : 
                                        
                                        <div className={`ml-1 text-sm capitalize ${colorKeysConfig[scan_status === 'Done' ? 'done' : scan_status === 'Initialized' ? 'init' : 'missing']} flex items-center gap-x-1`}>
                                            <div className="flex gap-x-1 items-center">
                                            { scan_status === "Done" ?
                                                <>
                                                    <span>Done ({latest_scan_date ? dateTimeFormater(latest_scan_date, 'yyyy/mm/dd') : scan_datetime ? dateTimeFormater(scan_datetime, 'yyyy/mm/dd') : ""})</span>
                                                </> :
                                                scan_status === "Initialized" ?
                                                <>
                                                    <span>Failed attempt ({scan_datetime && dateTimeFormater(scan_datetime, 'yyyy/mm/dd')})</span>
                                                </> :
                                                <>
                                                    <span>Missing</span>
                                                </>
                                            }
                                            { !baseline_date && 
                                                <CustomTooltip title={
                                                    <div className='flexRow'>
                                                        <AlertIcon className="mt-px" innerFill='#F8803D' />
                                                        <span className="text-sm ml-2">Missing Baseline</span>
                                                    </div>
                                                }>
                                                    <span>
                                                        <AlertIcon /> 
                                                    </span>
                                                </CustomTooltip>
                                            }
                                            </div>
                                        </div>     
                                    }
                                </div>
                        )
                        
                   })}
                   
                </div>
                <div className="flex justify-end gap-x-4 mt-2">
                    <ActionsPanel 
                        pid={pid} 
                        scan_id={scan_id} 
                        subject={subject} 
                        latest_report_id={latest_report_id} 
                        changeSetNotifState={changeSetNotifState} 
                        videoLink={ts} 
                    />
                </div>
            </div>
            {isOpen && <ExpandedDetails pid={pid} isOpen={isOpen} />}
        </div>
    );
};

// Sub-component for desktop view
const DesktopView = (props) => {
    const { ext_ref, subject, tenant_name, check_out, baseline_date, check_in, scan_status, scan_datetime, ts, scan_id, latest_scan_date,
            latest_report_id, pid, isOpen, toggle, changeSetNotifState } = props;
    const { categoryFilter } = useSelector((state) => state.dashboard);
    const moveOut = CategoryFilter.MOVE_IN === categoryFilter ? check_in : check_out;
    
    return (
        <>
            <tr key={pid} className="cursor-pointer" onClick={toggle}>
                <td className="p-4 text-sm font-medium border-b border-gray-100 max-w-in150 truncate" title={ext_ref}>{ext_ref}</td>
                <td className="p-4 text-sm border-b border-gray-100 max-w-in300 in-lg:max-w-in150 truncate" title={subject}>{subject}</td>
                <td className="p-4 text-sm border-b border-gray-100 max-w-in150 truncate" title={tenant_name}>{tenant_name}</td>
                <td className="p-4 text-sm border-b border-gray-100">{moveOut && dateTimeFormater(moveOut, 'yyyy/mm/dd')}</td>
                <td className={`p-4 text-sm border-b border-gray-100 capitalize ${colorKeysConfig[scan_status === 'Done' ? 'done' : scan_status === 'Initialized' ? 'init' : 'missing']}`}>
                    <div className="flex gap-x-1 items-center">
                        { scan_status === "Done" ?
                            <>
                            <span>Done ({latest_scan_date ? dateTimeFormater(latest_scan_date, 'yyyy/mm/dd') : scan_datetime ? dateTimeFormater(scan_datetime, 'yyyy/mm/dd') : ""})</span>
                            </> :
                            scan_status === "Initialized" ?
                            <>
                                <span>Failed attempt ({scan_datetime && dateTimeFormater(scan_datetime, 'yyyy/mm/dd')})</span>
                            </> :
                            <>
                                <span>Missing</span>
                            </>
                        }
                        { !baseline_date && 
                            <CustomTooltip title={
                                <div className='flexRow'>
                                    <AlertIcon className="mt-px" innerFill='#F8803D' />
                                    <span className="text-sm ml-2">Missing Baseline</span>
                                </div>
                            }>
                                <span>
                                    <AlertIcon /> 
                                </span>
                            </CustomTooltip>
                        }
                    </div>
                </td>
                <td className="p-4 text-sm border-b border-gray-100">
                    <div className="flex gap-x-4">
                        <ActionsPanel 
                            pid={pid} 
                            scan_id={scan_id} 
                            subject={subject} 
                            latest_report_id={latest_report_id} 
                            changeSetNotifState={changeSetNotifState}
                            videoLink={ts} 
                        />
                    </div>
                </td>
            </tr>
            {isOpen && <ExpandedDetails pid={pid} isOpen={isOpen} />}
        </>
    );
};

// Main component
const MoveInMoveOut = (props) => {
    const [notifState, setNotifState] = useState(null);
    const [isOpen, toggle] = useOpenController(false);
    const { isMobile } = useSelector((state) => state.config);
    
    
    
    const changeSetNotifState = useCallback((val) => {
        setNotifState(val);
    }, []);



    return (
        <>
            {isMobile ? (
                <MobileView {...props} changeSetNotifState={changeSetNotifState} isOpen={isOpen} toggle={toggle} />
            ) : (
                <DesktopView {...props} changeSetNotifState={changeSetNotifState} isOpen={isOpen} toggle={toggle} />
            )}
            {notifState && (
                typeof notifState === 'object' && !Array.isArray(notifState) && Object.keys(notifState).length > 0 ?
                    <SnackBar
                        open={Boolean(notifState)}
                        handleClose={() => setNotifState(null)}
                        severity={notifState.type}
                        message={notifState.msg}
                        duration={6000}
                    /> :
                    <SnackBar
                        open={Boolean(notifState)}
                        handleClose={() => setNotifState(null)}
                        severity={SeverityLevels.SUCCESS}
                        message={notifState}
                        duration={6000}
                    />
            )}
        </>
    );
};

export default MoveInMoveOut;

