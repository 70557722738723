import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// Styles
import "./scan_submissions.css";
// Components
import Pagination from "../../components/pagination/pagination.js";
import MainLoader from "../../components/loaders/main_loader/main_loader.js";
import EmptyState from "../../components/emptyState/empty_state.js";
import Notification from "../../components/side_notification/side_notification.js";
import MasterPopup from "../../components/popups/main_popup.js";
import Btn from "../../components/buttons/standard/btn.js";
import { SimpleDropdownContainer, SimpleInputDropdown } from "../../components/dropdowns/simple_dropdown/simple_dropdown.js";
import ConfirmPopup from "../../components/popups/confirmation/confirmation_popup.js";
import SimpleLoader from "../../components/scanner/loader/simple-loader.js";
// Utils
import { dateTimeFormater } from "../../utils/date_utils.js";
import { postReqOptBuilder } from "../../utils/main_utils.js";
// Assets
import searchUp from "../../assets/icons/search-up.svg";
import searchDown from "../../assets/icons/search-down.svg";
import searchDownSelect from "../../assets/icons/search-down-select.svg";
import searchUpSelect from "../../assets/icons/search-up-select.svg";
import chIcon from "../../assets/icons/check-circle.svg";
import CloseBtn from "../../assets/icons/Close.svg";
import dots from "../../assets/icons/dots.svg";
import RejectSubmissionPopup from "../../components/popups/reject_submission/reject_submission.js";
//constants
const itemsPerPage = 20;

export default function ScanSubmissions(props) {
    
    // state based variables
    const [isLoader, setLoader] = useState(true);
    const [allSubmissions, setAllSubmissions] = useState([]);
    const [currentSort, setCurrentSort] = useState({column: null, direction: null}); //sort direction(true ASC, false DESC)
    const [activeDD, setActiveDD] = useState(null);
    const [maxItems, setMaxItems] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [isMainEmptyState, setMainEmptyState] = useState(false);
    const [extendedTableItem, setExtendedTableItem] = useState(null);
    const [notifState, setNotifState] = useState(null);
    const [popupState, setPopupState] = useState(null);
    const [refresh, setRefresh] = useState(false);
    
    // Function to fetch scan submissions based on optional query parameters
    const fetchSubmissions = (queryParams = null) => {
        // Set loader to true indicating the start of the data fetch
        setLoader(true);
        // Construct the URL for the API call
        let url = props.para_be + "/scan/search_submissions";
        if (queryParams) {
            url = `${url}?${queryParams}`;
        }
        // Make the API call
        fetch(url, {credentials: "include"})
            .then((response) => response.json())
            .then((response) => {
                // Check if the API call was successful
                if (response.status === 200) {
                    // Check if there is data in the response
                    if (
                        response.result &&
                        Object.keys(response.result).length > 0
                    ) {
                        // Set the state with the received data
                        setAllSubmissions(response.result.data);
                        setMaxItems(response.result.maxRows);
                        // If the component was in an empty state, update it
                        if (isMainEmptyState) setMainEmptyState(false);
                    } else {
                        // Set empty state if there is no data
                        setMainEmptyState(true);
                    }
                } else {
                    // Handle error response
                    setNotifState({
                        text: response.msg || "Error in fetching data",
                        type: "error",
                    });
                }
            })
            .catch((error) => {
                // Handle API call error
                setMainEmptyState(true);
                setNotifState({ text: "Can't fetch data", type: "error" });
            })
            .finally(() => {
                // Set loader to false regardless of success or failure
                setLoader(false);
            });
    };

    // Effect hook to fetch tenancies when the 'refresh' dependency changes
    useEffect(() => {
        fetchSubmissions();
    }, [refresh]);

    const handleSortUnits = (index) => {
        let sortDirection =
            currentSort.column !== index ? false : !currentSort.direction;
        setCurrentPage(1);
        setCurrentSort({ column: index, direction: sortDirection });
    };

    const handleChangePage = (page) => {
        const params = `lowerLimit=${(page - 1) * itemsPerPage}&upperLimit=${
            page * itemsPerPage
        } `;
        fetchSubmissions(params);
        setCurrentPage(page);
    };

    useEffect(() => {
        if (activeDD) {
            document.body.addEventListener("click", closeDD);
            return () => {
                document.body.removeEventListener("click", closeDD);
            };
        }
    }, [activeDD]);

    useEffect(() => {
        closeDD();
    }, [popupState]);
    
    const closeDD = () => {
        if (activeDD) setActiveDD(null);
    };
    
    const handleDropDown = (event, t) => {
        event.stopPropagation();
        if (activeDD === t) closeDD();
        else {
            if (activeDD) closeDD();
            setActiveDD(t);
        }
    };

    const openRejectSubmissionsPopup = (submissions_id) => {
        setPopupState([<RejectSubmissionPopup
            onRejection={(msg) => { handleSubmission(submissions_id, 'reject', msg); }}
            onCancel={handleClosePopup}
            closeFunc={handleClosePopup}/>,
        {closeFunc: handleClosePopup}]);
        // setPopupState([<ConfirmPopup
        //     headlineText={"Reject Submission"}
        //     mainText={"Are you sure you want to reject this submission?\nOnce reject, you won't be able to recover it."}
        //     confirmText={"Reject"}
        //     declineText={"Cancel"}
        //     confirmChoice={() => { handleSubmission(submissions_id, 'reject') }}
        //     declineChoice={handleClosePopup}
        //     closeFunc={handleClosePopup}/>,
        // {closeFunc: handleClosePopup}]);
    }

    const openApproveSubmissionsPopup = (submissions_id) => {
        setPopupState([<ConfirmPopup
            headlineText={"Approve Submission"}
            mainText={"Are you sure you want to approve this submission?"}
            confirmText={"Approve"}
            declineText={"Cancel"}
            extraContent={
                <div className="checkbox-wrapper flexRow">
                    <input type="checkbox" className="mul-selection-checkbox" id="sendMsg_approve" value="sendMsg" />
                    <div className="text-2">Send Tenant a Message of Approval</div>
                </div>
            }
            confirmChoice={() => { handleSubmission(submissions_id, 'approve') }}
            declineChoice={handleClosePopup}
            closeFunc={handleClosePopup}/>,
        {closeFunc: handleClosePopup}]);
    }

    const handleSubmission = (submissions_id, selected_action, msg=null) => {
        let should_send_msg = document.getElementById("sendMsg_approve")?.checked;
        setPopupState([<SimpleLoader />, {}]);
        fetch(
            props.para_be + "/scan/" + selected_action + "_pending", 
            postReqOptBuilder(
                {
                    rid: submissions_id, 
                    ...((selected_action === 'approve' && should_send_msg) ? {send_msg: "1"} : {}), 
                    ...(msg ? {msg: msg} : {})
                }
            )
        )
        .then((response) => response.json())
        .then((response) => {
            handleClosePopup(true);
            if (response.status === 200) {
                setNotifState({
                    text: selected_action === "approve" ? 
                            "Submission approved successfully" : 
                            "Submissions rejected successfully", 
                    type: "success"
                });
            } else {
                setNotifState({
                    text: selected_action === "approve" ?
                            "Something went wrong while approving submission" :
                            "Something went wrong while rejecting submission", 
                    type: "error"
                });
            }
        })
        .catch((error) => {
            handleClosePopup();
            setNotifState({
                text: selected_action === "approve" ?
                        "Something went wrong while approving submission" :
                        "Something went wrong while rejecting submission", 
                type: "error"
            });
        });
    }

    const handleShowScanVideo = (subject, target_ts) => {
        setPopupState(
            [
            <div className="bsp-container">
                <div>
                    <div className="h2 blue-headline">Submission - {subject}</div>
                    <div className="bsp-body custom-scrollbar-1">
                        <video src={"https://aiv2.paraspot.ai/inspection/scan/media/" + target_ts + ".mp4"} controls playsInline autoPlay/>
                    </div>
                </div>
            </div>, 
            {closeFunc: handleClosePopup, extraClasses: "has-bsp-container"}]);
    };

    const handleCloseNotif = () => {
        setNotifState(null);
    };
    
    const handleClosePopup = (refresh = false) => {
        setPopupState(null);
        if (refresh) handleChangePage(currentPage);
    };
    
    // const handleEditTencies = (item) => {
    //     setPopupState([
    //         <UpdateTenancy
    //             closeFunc={handleClosePopup}
    //             para_be={props.para_be}
    //             updatedItem={item.client_name}
    //             checkin={item.checkin}
    //             checkout={item.checkout}
    //             tenancy_id={item.tenancy_id}
    //             refresh={refresh}
    //             setRefresh={setRefresh}
    //         />,
    //         { closeFunc: handleClosePopup },
    //     ]);
    // };

    return (
        <section className="mgmt-main scans-submissions">
            <Helmet>
                <title>Paraspot | Scan Submissions</title>
                <meta name="description" content="." />
                <meta property="og:title" content="Paraspot | Scan Submissions" />
                <meta property="og:description" content="." />
            </Helmet>
            <div className="mgmt-header flexRow">
                <div className="h1">Scan Submissions</div>
                <div className ="mgmt-sort mobile-only">
                    <SimpleInputDropdown
                        placeholder="Sort by"
                        keepPlaceholder={true}
                        selectorClasses="pad-5-ud"
                        borderedItems={true}
                        onclick={(item) => handleSortUnits(item === 'reference' ? 1 : item === 'unit' ? 2 : 3)}
                        items={[['reference', 'Reference'], ['unit', 'Unit'], ['tenant', 'Tenant'], ['date_scanned', 'Date Scanned']].reduce((dict, [k, v], idx) => {
                            let isCurrentSort = currentSort.column === idx+1;
                            return {[k]: {'present': v, 'disabled': false, 'preItemText': 
                                            <div className="sort-icons flexColumn">
                                                <img src={(isCurrentSort && currentSort.direction === true) ? searchUpSelect : searchUp} alt="arrow-up"/>
                                                <img src={(isCurrentSort && currentSort.direction === false) ? searchDownSelect : searchDown} alt="arrow-down"/>
                                            </div>}, ...dict}
                        }, {})}/>
                </div>
                {/* <div className="mgmt-header-btns flexRow">
                    <Btn 
                        text="Filter"
                        type="secondary"
                        onclick={() => handleOpenSearchFilterPopup()}/>
                </div> */}
            </div>
            <div className="mgmt-table">
                <div className="top-pg-container">
                    <div>
                        {currentPage < 2 ? 1 : (currentPage - 1) * itemsPerPage + 1}
                        -
                        {itemsPerPage * currentPage >= maxItems ? maxItems : itemsPerPage * currentPage}{" "}
                        of {maxItems} Submissions
                    </div>
                </div>
                <table cellSpacing="0" cellPadding="0">
                    <thead className="hide-mobile">
                        <tr>
                            {Object.entries({
                                    Reference: {
                                        sortable: true,
                                        className: "th-ref",
                                    },
                                    Unit: {
                                        sortable: true,
                                        className: "th-subject",
                                    },
                                    Tenant: {
                                        sortable: true,
                                        className: "th-name",
                                    },
                                    "Date Scanned": {
                                        sortable: true,
                                        className: "th-ds",
                                    },
                                    Action: {
                                        sortable: false,
                                        className: "th-act",
                                    },
                                }).map(([k, v], index) => {
                                    let isCurrentSort =
                                        currentSort.column === index + 1 &&
                                        v["sortable"];
                                    return (
                                        <th className={v["className"] + (isCurrentSort ? " sort-active" : "")}>
                                            <div {...(v["sortable"] ? {onClick: () => handleSortUnits(index + 1)} : {})}>
                                                <span>{k}</span>
                                                {v["sortable"] && (
                                                    <div className="sort-icons flexColumn">
                                                        <img
                                                            src={isCurrentSort && currentSort.direction === true ? searchUpSelect : searchUp}
                                                            alt="arrow-up" />
                                                        <img
                                                            src={isCurrentSort && currentSort.direction === false ? searchDownSelect : searchDown}
                                                            alt="arrow-down" />
                                                    </div>
                                                )}
                                            </div>
                                        </th>
                                    );
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoader ? (
                            <MainLoader />
                        ) : isMainEmptyState ? (
                            <EmptyState
                                text={"No submissions yet"}
                                size="lg"
                            />
                        ) : (
                            allSubmissions?.map(function (item, index) {
                                // Extracting relevant properties from the API response
                                const [submissions_id, pid, ext_ref, subject, client_name, date_submitted, scan_name] = item;
                                
                                const item_id = "mgmt_item_" + index;
                                const dateTimeToPrintSubmitted = dateTimeFormater(date_submitted, "dd/mm/yyyy");

                                const extendedSection = "";
                                
                                return (
                                    <>
                                        <tr id={item_id} key={item_id} onClick={() => { 
                                                console.log("[i] Changing table item extension view to:", submissions_id !== extendedTableItem ? submissions_id : null); 
                                                setExtendedTableItem(submissions_id !== extendedTableItem ? submissions_id : null); 
                                            }}>
                                            <td className={currentSort.column === 1 ? "sort-active" : ""} data-th="Reference">{ext_ref}</td>
                                            <td className={currentSort.column === 2 ? "sort-active" : ""} data-th="Unit" title={subject}>{subject}</td>
                                            <td className={currentSort.column === 3 ? "sort-active" : ""} data-th="Tenant">
                                                {client_name}
                                            </td>
                                            <td className={currentSort.column === 4 ? "sort-active" : ""} data-th="Date Scanned">
                                                {dateTimeToPrintSubmitted}
                                            </td>
                                            <td data-th="Action" className="th-act">
                                                <div className="report-btns">
                                                    <div className="simple-btn" onClick={() => handleShowScanVideo(subject, scan_name)}>Watch Scan</div>
                                                    <div className="simple-btn img-btn" onClick={() => openApproveSubmissionsPopup(submissions_id)}>
                                                        <img src={chIcon} alt="approve" />
                                                    </div>
                                                    <div className="simple-btn img-btn" onClick={() => openRejectSubmissionsPopup(submissions_id)}>
                                                        <img src={CloseBtn} alt="reject" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        {extendedSection}
                                    </>
                                );
                            })
                        )}
                    </tbody>
                </table>

                {isMainEmptyState ? "" : (
                    <Pagination
                        currentPage={currentPage}
                        maxPages={Math.ceil(maxItems / itemsPerPage)}
                        handleChangePage={(page) => handleChangePage(page)}
                        setNotifState={setNotifState}
                        itemsPerPage={itemsPerPage}
                    />
                )}
            </div>

            {notifState ? (
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState.text}
                    type={notifState.type}
                />
            ) : ""}
            {popupState ? (
                <MasterPopup {...popupState[1]}>{popupState[0]}</MasterPopup>
            ) : ""}
        </section>
    );
}