import React from 'react';
import { useSelector } from 'react-redux';
import MoveInMoveOut from './components/MoveInMoveOut';
import Reports from './components/Reports';
import Scans from './components/Scans';
import { CategoryFilter } from '../../../../../../constants';


const selectTable = (category, props) => {
  switch (category) {
    case CategoryFilter.MOVE_IN:
    case CategoryFilter.MOVE_OUT:
      return <MoveInMoveOut {...props} />;
    case CategoryFilter.REPORTS:
      return <Reports {...props}  />;
    case CategoryFilter.SCANS:
      return <Scans {...props}  />;
    default:
      return null;
  }
};

const DataRow = (props) => {

  const { categoryFilter } = useSelector((state) => state.dashboard);

  const tableComponent = () => selectTable(categoryFilter, props);

  return <>{tableComponent()}</>;
};

export default DataRow;