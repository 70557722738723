import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

const TableOptionsMenu = ({ items }) => {
  const dispatch = useDispatch();

  return (
    <Menu as="div" className="relative">
      <Menu.Button 
        className="inline-flex items-center justify-center rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
        onClick={(e) => e.stopPropagation()}
      >
        <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
      </Menu.Button>
      <Menu.Items className="absolute right-0 z-10 mt-1 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {items.map((item, index) => (
          <Menu.Item key={index}>
            <div
              className={`flex p-2 ${item.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
              onClick={(e) => {
                e.stopPropagation();
                if (!item.disabled) item.onClick(dispatch);
              }}
            >
              <item.icon />
              <span className="ml-2">{item.label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default TableOptionsMenu;