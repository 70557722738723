
import React, { useEffect,useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import Notification from "../../../../../../../components/side_notification/side_notification";
import EmptyState from "../../../../../../../components/emptyState/empty_state";
import CircularProgress from '../../../../../../../components/loaders/CircularLoader';
import BaseModal from '../../../../../../../components/Modal';
import {dateTimeFormater,isToday} from "../../../../../../../utils/date_utils";
import { capitalizeString } from "../../../../../../../utils/main_utils";
import { para_be } from '../../../../../../../config'

const AllReports = ({ 
    open, 
    handleClose, 
    pid, 
    subject 
    }) => {

    const [isLoading, setIsLoading] = useState(true);
    const [allReportsData, setAllReportsData] = useState(null);
    const [notifState, setNotifState] = useState(null);
    const { page, filters, searchValue:search, sortDirection, sortColumn } = useSelector(state => state.dashboard);
    
    useEffect(() => {
        if(pid){
            setIsLoading(true);
            fetch(para_be + '/reports/get_all?cid=1&pid=' + pid, {credentials: "include"})
            .then(response => response.json())
            .then(response => {
                //DATA 0-report id, 1-status, 2-datetime
                console.log(response)
                if (response.status === 200) {
                    if (response.result.length > 0) {
                        setAllReportsData(response.result);
                    }   
                } else setNotifState(response.msg);
                setIsLoading(false);
            })
            .catch(error => {
                setNotifState("An error has occurred, please try again later");
                setIsLoading(false);
            });
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pid]);

    const handleCloseNotif = () => {
        setNotifState(null);
    }
  

  return (
    <BaseModal
        open={open}
        onClose={handleClose}
        minWidth={400}
        >
        <div className="ar-container">
            {isLoading ? <div className='flex justify-center items-center h-72'>
                                <CircularProgress size={24} />
                            </div> :
                <div>
                    <div className="h2 blue-headline">All reports</div>
                    <div className="text-1 ar-address">{subject}</div>
                    <>
                        {!allReportsData ? <EmptyState text="No reports available." size="md"/> :
                            allReportsData.map((item) => {
                                const newId = uuidv4();
                                const rowStatus = (item[1] !== "1" ? null : (item[1] === "1" ? "Pass" : "Fail"));
                                const format = isToday(item[2]) ? "HH:MM" : "dd/mm/yyyy";
                                const dateTimeToPrint = dateTimeFormater(item[2], format);
                                const linkParams = {
                                    pathname: "report/" + item[0],
                                    state: {"pid": pid,
                                            "reportId": item[0],
                                            "sortColumn": sortColumn,
                                            "sortDirection": sortDirection,
                                            "search": search,
                                            "filters": filters,
                                            "page": page,
                                            "subject": subject,
                                            "isAllReports": true}
                                };
                                return (
                                    <div key={newId}>
                                        <Link className="ar-link" to={linkParams}>
                                            <div className="ar-item-header">
                                                <div className="text-1-2 ar-item-date">
                                                    {dateTimeToPrint}
                                                    {rowStatus == null ? "" : <span className={"ls-status para-label para-l-" + rowStatus.toLocaleLowerCase()}>{rowStatus}</span>}
                                                </div>
                                            </div>
                                            <div className="ar-item-breakdown">
                                                {
                                                    ["inventory", "structural_defects", "inventory_defects", "organization"].map((arKey) => {
                                                        return (
                                                            <div className="ar-item text-3">
                                                                <span>{capitalizeString(arKey.split("_").join(" "))}</span>
                                                                <span>{item[3] && item[3]?.hasOwnProperty(arKey) ? item[3][arKey] : "?"}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })
                        }
                    </>
                </div>
            }

            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div> 
    </BaseModal>
  );
};

export default AllReports;