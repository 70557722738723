export const SeverityLevels = {
    SUCCESS: "success",
    ERROR: "error",
    WARNING: "warning",
    INFO: "info"
};


export const CustomerAction = {
    CREATE_CUSTOMER: "create_customer",
    LOG_IN: "Log_In"
};


export const CategoryFilter = {
    MOVE_IN: 'move-in',
    MOVE_OUT: 'move-out',
    REPORTS: 'reports',
    SCANS: 'scans'
};