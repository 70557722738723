import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup } from '@headlessui/react';
import useDebounce from '../../../../../../hooks/useDebounce';
import { fetchTableData, fetchAllCount, setCategoryFilter, fetchStats, 
        setPage, setFilters, setLowerLimit, setUpperLimit } from '../../../../../../store/slices/dashboardSlice';

import RingsLoader from '../../../../../../components/loaders/RingsLoader'
import { CategoryFilter } from '../../../../../../constants';
import CircleTopDown from './assets/CircleTopDown';
import CircleTopUp from './assets/CircleTopUp';
import PlayIcon from './assets/PlayIcon';
import ReportsIcon from './assets/ReportsIcon';

const updatesMethods = [
  { id: 1, title: 'Move-Outs', titleKey: CategoryFilter.MOVE_OUT, icon: CircleTopDown },
  { id: 2, title: 'Move-Ins', titleKey: CategoryFilter.MOVE_IN, icon: CircleTopUp },
  { id: 3, title: 'New Scans', titleKey: CategoryFilter.SCANS, icon: PlayIcon },
  { id: 4, title: 'New Reports', titleKey: CategoryFilter.REPORTS, icon: ReportsIcon},
];




const RadioGroupCards = ({ rangeValue }) => {
  
  
  const dispatch = useDispatch();
  const {isMobile} = useSelector(state => state.config);
  const { page, pageSize, allCount, loading,categoryFilter,statsLoading,filters } = useSelector(state => state.dashboard);  
  const debouncedPage = useDebounce(page, 500);
 

  const updatesMethod = useMemo(() => {
    const result = updatesMethods.filter(item => item.titleKey === categoryFilter);
    return result.pop(); 
  }, [categoryFilter])
  

  const setSelectedUpdatesMethods =(obj)=>{
     dispatch(setCategoryFilter(obj.titleKey))
  }



  useEffect(() => {
     const init = async () =>{
        const filtersObj = {
          category: updatesMethod.titleKey,
          date_range: {
            opt: "range",
            value: rangeValue,
            filter_type: "date",
            column: 0
          },
        };
    
    
        const body =
        {
            lowerLimit: (debouncedPage - 1) * pageSize,
            upperLimit: debouncedPage * pageSize,
            filters:filtersObj,
            searchValue: "",
            sortDirection: false,
            sortColumn: 0,
        }
    
      
        dispatch(fetchTableData(body))
        dispatch(fetchAllCount(body))
        dispatch(setCategoryFilter(updatesMethod.titleKey));
        await dispatch(setFilters(filtersObj));
        if(page === 1){
          dispatch(fetchStats({
            filters: filtersObj,
            category: updatesMethod.titleKey,
            searchValue: ""
          }))
        }
          
     }

     init()
    

  }, [dispatch, debouncedPage, updatesMethod.titleKey,rangeValue]);

  


  useEffect(()=>{
    dispatch(setPage(1))
    dispatch(setLowerLimit(0))
    dispatch(setUpperLimit(20))
  },[updatesMethod.titleKey])

  






  return (
    <RadioGroup value={updatesMethod} 
     onChange={setSelectedUpdatesMethods} 
     className={`mt-4 grid grid-cols-2 gap-x-4 gap-y-6 xl:grid-cols-4`}
     disabled={loading || (!isMobile && statsLoading)}
    >
      {updatesMethods.map(updateMethod => {
       
       const IconComponent = updateMethod.icon;
        
        return (
        <RadioGroup.Option
          key={updateMethod.id}
          value={updateMethod}
          className={({ checked }) =>
            `relative flex ${(loading || (!isMobile && statsLoading)) ? 'cursor-default' : 'cursor-pointer'} rounded-lg border ${checked ? 'bg-indigo-100 border-transparent' : 'bg-gray-100 border-gray-300'} p-4 shadow-sm focus:outline-none`
          }
        >
          {({ checked }) => (
            <>
              <span className="flex flex-1">
                <span className="flex flex-col">
                  <div className="flex md:flex lg:text-sm text-xs font-normal text-gray-900 flex ">
                    <IconComponent styleClass="lg:mt-0.5"/>
                    <span className='truncate'>{updateMethod.title}</span>
                  </div>
                  <span className="mt-6 text-2xl font-bold text-gray-900">{
                  !allCount 
                    ? 
                    <RingsLoader
                      visible={true}
                      height="30"
                      width="30"
                      color="#1890ff"
                      ariaLabel="rings-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    /> 
                    : allCount[updateMethod.titleKey] > 0 ? allCount[updateMethod.titleKey] : 'N/A'}</span>
                </span>
              </span>
              <span
                aria-hidden="true"
                className={`pointer-events-none absolute -inset-px rounded-lg border-2 border-transparent ${checked ? 'border-indigo-500' : 'border-transparent'}`}
              />
            </>
          )}
        </RadioGroup.Option>
        )
     })}
    </RadioGroup>
  );
};

export default RadioGroupCards;
