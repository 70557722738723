import { useState, useEffect } from 'react';
import axios from 'axios';
import { para_be } from '../../../../../../../config'


const useFetchExtendedInfo = (pid, isOpen) => {
    const [extendedInfo, setExtendedInfo] = useState({});
    const [isExtendedInfoLoading, setIsExtendedInfoLoading] = useState(true);

    useEffect(() => {
        const fetchExtendedInfo = async () => {
            if (!isOpen || !pid) {
                return; // Early return if conditions are not met
            }

            setIsExtendedInfoLoading(true); // Start loading

            try {
                const response = await axios.get(`${para_be}/units/get_extended_info?pid=${pid}`, { withCredentials: true });

                console.log(response,pid)
                if (response.status === 200) {
                    setExtendedInfo(response.data.result);
                } else {
                    console.log("Error:", response.data);
                }
            } catch (error) {
                console.error('Failed to fetch extended info:', error);
            } finally {
                setIsExtendedInfoLoading(false); 
            }
        };

        fetchExtendedInfo();
    }, [pid, isOpen, para_be]);

    const extendedInfoArr = Object.entries(extendedInfo);

    return { extendedInfo, isExtendedInfoLoading };
}

export default useFetchExtendedInfo;