import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//components
import ConfirmationDialog from '../../../../../../components/ConfirmationDialog';
import CircularLoader from '../../../../../../components/loaders/CircularLoader';
import { fetchSubscriptionData, removeSubscription } from '../../../../../../store/slices/subscriptionsSlice';
//assets
import { BanknotesIcon } from '@heroicons/react/20/solid';
//utils
import { classNames } from '../../../../../../utils/main_utils';

const statusStyles = {
    active: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
};

const ShowSubscription = () => {
    const dispatch = useDispatch();
    const subscription = useSelector(state => state.subscription.data.subscription);
    const loading = useSelector(state => state.subscription.loading);
    const error = useSelector(state => state.subscription.error);

    const [open, setOpen] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchSubscriptionData()).unwrap();
            } catch (err) {
                console.error('Failed to fetch subscription data:', err);
            }
        };
        fetchData();
    }, []);

    const handleClickOpen = () => {
        setSelectedSubscription(subscription);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedSubscription(null);
    };

    const handleRemove = async () => {
        if (!selectedSubscription) return;
        try {
            await dispatch(removeSubscription(selectedSubscription.id)).unwrap();
            history.push(`/settings/payments/createSubscription`);
        } catch (err) {
            console.error('Failed to remove subscription:', err);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-full">
                <CircularLoader size={36} color="secondary" />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!subscription) {
        return <h2 className="mx-auto max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">Subscription not found</h2>;
    }

    return (
        <>
            <div className="min-h-full">
                <div className="flex flex-1 flex-col">
                    <main className="flex-1 pb-8">
                        <h2 className="mx-auto max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">Subscription</h2>

                        <div className="hidden sm:block">
                            <div className="mx-auto max-w-6xl">
                                <div className="mt-2 flex flex-col">
                                    <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                                                        Charge
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                                                        Created
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                <tr className="bg-white">
                                                    <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                        <div className="flex">
                                                            <BanknotesIcon 
                                                                aria-hidden="true"
                                                                className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                                                            <span className="ml-2 truncate text-gray-500 group-hover:text-gray-900">
                                                                ${subscription.plan.amount / 100} / {subscription.plan.interval}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500">
                                                        <time dateTime={new Date(subscription.created * 1000).toISOString()}>{new Date(subscription.created * 1000).toLocaleDateString()}</time>
                                                    </td>
                                                    <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                        <span
                                                            className={classNames(
                                                                statusStyles[subscription.status] || 'bg-gray-100 text-gray-800',
                                                                'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
                                                            )}
                                                        >
                                                            {subscription.status}
                                                        </span>
                                                    </td>
                                                    <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                        <button className="text-red-600 hover:text-red-900" onClick={handleClickOpen}>
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shadow sm:hidden">
                            <ul role="list" className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                                <li>
                                    <div className="block bg-white px-4 py-4 hover:bg-gray-50">
                                        <span className="flex items-center space-x-4">
                                            <span className="flex flex-1 space-x-2 truncate">
                                                <BanknotesIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                                                <span className="flex flex-col truncate text-sm text-gray-500">
                                                    <span className="truncate">${subscription.plan.amount / 100} / {subscription.plan.interval}</span>
                                                    <time dateTime={new Date(subscription.created * 1000).toISOString()}>{new Date(subscription.created * 1000).toLocaleDateString()}</time>
                                                </span>
                                            </span>
                                        </span>
                                        <div className="flex justify-between mt-2">
                                            <span
                                                className={classNames(
                                                    statusStyles[subscription.status] || 'bg-gray-100 text-gray-800',
                                                    'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize'
                                                )}
                                            >
                                                {subscription.status}
                                            </span>
                                            <button className="text-red-600 hover:text-red-900 text-sm font-medium" onClick={handleClickOpen}>
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </main>
                </div>
            </div>

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={open}
                handleClose={handleClose}
                handleRemove={handleRemove}
                text={'Are you sure you want to remove this subscription? This action cannot be undone.'}
                header={'Remove Subscription'} />
        </>
    );
};

export default ShowSubscription;
