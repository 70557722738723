import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu } from '@headlessui/react';

import { setSidebarOpenMobile } from '../../store/slices/configSlice';
import SearchInput from './components/Search';
import BellNotification from './components/BellNotification';

import { Bars3Icon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';



const StickyHeader = () => {
  const {isMobile} = useSelector(state => state.config);
  const dispatch = useDispatch();

  return (
    <div className="sticky top-0 bg-white z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 px-4 shadow-sm sm:px-6 lg:px-8">
        <div className="flex flex-1">
            <div className="bg-gray-100 mr-4 rounded-full h-8 w-8 xl:hidden flex justify-center items-center cursor-pointer">
                <button type="button" onClick={() => dispatch(setSidebarOpenMobile(true))} className="-m-2.5 p-2.5 text-white">
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon aria-hidden="true" className="h-5 w-5 text-gray-800" />
                </button>
            </div>
            <SearchInput  />
        </div>
        {!isMobile && 
            <>    
                <Menu as="div" className="relative inline-block px-3 text-left">
                    <div>
                        <Menu.Button disabled className="d-none group w-full rounded-md bg-gray-100 px-3.5 py-2 text-left text-sm font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            <span className="flex w-full items-center justify-between">
                                <span className="flex min-w-0 items-center justify-between space-x-3">
                                    <span className="flex min-w-0 flex-1 flex-col">
                                        <span className="truncate text-sm font-medium text-gray-900">All Properties</span>
                                    </span>
                                </span>
                                <ChevronDownIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" />
                            </span>
                        </Menu.Button>
                    </div>
                    <Menu.Items transition className="absolute left-0 right-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                        <div className="py-1">
                            <Menu.Item>
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                    View profile
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                    Settings
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                    Notifications
                                </a>
                            </Menu.Item>
                        </div>
                        <div className="py-1">
                            <Menu.Item>
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                    Get desktop app
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                    Support
                                </a>
                            </Menu.Item>
                        </div>
                        <div className="py-1">
                            <Menu.Item>
                                <a href="#" className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                    Logout
                                </a>
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Menu>
            </>
        }
        <BellNotification />
    </div>
  )
}

export default StickyHeader
