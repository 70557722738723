import React from 'react';
import { useSelector } from 'react-redux';
import useAuthenticate from '../../hooks/useAuthenticate';
import ManagementHeader from './components/ManagementHeader';
import CardsSection from './components/CardsSection';
import ScanCompletionSection from './components/ScanCompletionSection';
import TableWidget from './components/TableWidget';


const ManagementDev = () => {
    const { settingUser } = useAuthenticate();
    const { isMobile } = useSelector(state => state.config);

    return (
        <>
            <main className="flex-1">
                {/* Header */}
                <ManagementHeader userName={settingUser?.fullName} />

                {/* Cards Section */}
                <div className="mt-2">
                    <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-6">
                        <div className="mt-2 flex w-full h-full flex flex-col sm:flex-row">
                            <CardsSection />
                            {/* Scan Completion Section */}
                            {!isMobile && <ScanCompletionSection />}
                        </div>
                    </div>
                </div>
                {/* Table Section */}
                <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-6 mt-8 mb-8">
                    <div className="mt-2 flex flex-col">
                        <TableWidget />
                    </div>
                </div>
            </main>
        </>
    );
};

export default ManagementDev;
