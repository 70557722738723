import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
//slices
import { createCustomer } from '../../../../../../../../store/slices/customerSlice';
//utils
import { isHaveAccount as hasIdStartingWithCus } from '../../../../utils';

const Step1CreateCustomer = forwardRef(({ handleNext }, ref) => {
    const dispatch = useDispatch();
    const [isHaveAccount, setHaveAccount] = useState(false);
    const { data: customers, loading } = useSelector(state => state.customer);

    const [customerData, setCustomerData] = useState({
        name: '',
        email: '',
        description: '',
    });

    const handleInputChange = e => {
        const { name, value } = e.target;
        setCustomerData(prev => ({...prev, [name]: value}));
    };

    useEffect(() => {
        if (customers) {
            const haveAccount = hasIdStartingWithCus(customers.customer_data);
            setHaveAccount(haveAccount);
        }
    }, [customers]);

    useImperativeHandle(ref, () => ({ handleSubmit, }));

    const handleSubmit = async () => {
        try {
            if (!isHaveAccount) {
                const resultAction = await dispatch(createCustomer(customerData));
                if (createCustomer.rejected.match(resultAction)) {
                    throw new Error(resultAction.payload || 'Failed to create customer');
                }
            }
        } catch (error) {
            throw error;
        }
    };

    return (
        <div className="p-4">
            {isHaveAccount &&
                <>
                    <p className="mb-2 text-gray-600 text-sm mt-4">If you already have an account, click the button below to log in. You will be automatically connected.</p>

                    <Button variant="contained" color="primary" onClick={handleNext} disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Log in'}
                    </Button>
                </>
            }
            {!isHaveAccount && 
                <>
                    <div className="mt-6">
                        <Divider className="my-8 mt-4" />
                    </div>

                    <h2 className="text-xl font-bold mt-4">Create New Customer</h2>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">Name</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            type="text"
                            name="name"
                            value={customerData.name}
                            onChange={handleInputChange}
                            required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">Email</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="email"
                            name="email"
                            value={customerData.email}
                            onChange={handleInputChange}
                            required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">Description</label>
                        <textarea
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="description"
                            name="description"
                            value={customerData.description}
                            onChange={handleInputChange} />
                    </div>
                </>
            }
        </div>
    );
});

export default Step1CreateCustomer;
